import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Table, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import { NormalButtonSmall } from "../Common/CommonButtons";
import {
  CheckSquareOutlined,
  DiffOutlined,
  ExportOutlined,
  LineChartOutlined,
  UserOutlined,
  UserSwitchOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  PlaySquareOutlined
} from "@ant-design/icons";
import { size } from "../../helpers/pageHelper";
import {
  CheckProjectRightsAccess,
  CheckUserRightsAccess
} from "../MicroComponents/LicenseAccess";
import { isNil, isUndefined } from "lodash-es";
import {
  openFinishTransportModal,
  setTransportToFinish
} from "../../state/modalsSlice";
import {
  closeProjectModal,
  openMoveDeviceModal,
  setParameterId
} from "../../state/projectSlice";
import dayjs from "dayjs";
import { FatIcon } from "../../icons";
import { useDispatch } from "react-redux";
import {
  ProjectWithUserRights,
  useDownloadSerializedDataQuery
} from "../../state/cargologRestApi";
import { warningColor } from "../../pages/ProjectsPage";
import { ColumnsType } from "antd/es/table";
import { DeviceData } from "../DevicesPage/DeviceMap";
import { skipToken } from "@reduxjs/toolkit/query";
import { unpackDatxAsync } from "../../state/openDatxSlice";
import { unpackFile } from "../../state/compareGraphsSlice";
import { useNavigate } from "react-router";
const { Text, Paragraph } = Typography;

interface IProps {
  deviceData: DeviceData[];
  project?: ProjectWithUserRights;
  loading: boolean;
}
const RecordingsTabTable = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { project, loading, deviceData } = props;

  const deviceColumns: ColumnsType<DeviceData> = [
    {
      title: t("SerialNumber"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (serialNumber: string, deviceData) => (
        <Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            marginBottom: 0,
            borderLeft: "5px solid " + deviceData.lineColor,
            paddingLeft: size.m1
          }}
        >
          {serialNumber}
        </Paragraph>
      ),
      sorter: (a, b) =>
        a.serialNumber
          .toLowerCase()
          .localeCompare(b.serialNumber.toLowerCase(), "sv")
    },
    {
      title: t("RecordingStart"),
      dataIndex: "recordingStart",
      key: "recordingStart",
      render: (recordingStart: string) => {
        if (isNil(recordingStart)) {
          return <Text disabled>{t("NoDataUploaded")}</Text>;
        }
        return dayjs.utc(recordingStart).local().format("YYYY-MM-DD HH:mm");
      },
      sorter: (a, b) => {
        if (isNil(a.recordingStart)) {
          return -1;
        } else if (isNil(b.recordingStart)) {
          return 1;
        } else {
          return (
            dayjs(a.recordingStart).unix() - dayjs(b.recordingStart).unix()
          );
        }
      }
    },
    {
      title: t("LatestUpload"),
      dataIndex: "latestData",
      key: "latestData",
      render: (latestData: string, deviceData) => {
        if (latestData === "") {
          return <Text disabled>{t("NoDataUploaded")}</Text>;
        }
        return (
          <>
            {dayjs.utc(latestData).local().format("YYYY-MM-DD HH:mm")}
            {deviceData.lastUploadType === "internal_user" && (
              <UserOutlined style={{ marginLeft: size.s1 }} />
            )}
            {deviceData.lastUploadType === "external_user" && (
              <UserSwitchOutlined style={{ marginLeft: size.s1 }} />
            )}
            {deviceData.lastUploadType === "lte_device" && (
              <FatIcon
                style={{ marginLeft: size.s1, transform: "translateY(12%)" }}
              />
            )}
          </>
        );
      },
      sorter: (a, b) => {
        if (a.latestData === "") {
          return -1;
        } else if (b.latestData === "") {
          return 1;
        } else {
          return dayjs(a.latestData).unix() - dayjs(b.latestData).unix();
        }
      }
    },
    {
      title: t("Alarms"),
      dataIndex: "alarms",
      key: "alarms",
      render: (alarms: number) => (
        <Statistic
          style={{ textAlign: "start" }}
          value={alarms}
          prefix={alarms > 0 ? <WarningOutlined /> : <CheckCircleOutlined />}
          valueStyle={{ color: warningColor(alarms > 0), fontSize: 14 }}
        />
      ),
      sorter: (a, b) => (a.alarms ?? 0) - (b.alarms ?? 0)
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) =>
        isActive ? (
          <Text style={{ paddingRight: size.s1, color: warningColor(false) }}>
            <PlaySquareOutlined /> {t("StatusActive")}
          </Text>
        ) : (
          <Text disabled style={{ paddingRight: size.s1 }}>
            <CheckSquareOutlined /> {t("StatusFinished")}
          </Text>
        ),
      sorter: (a, b) => (a.isActive ? 1 : 0) - (b.isActive ? 1 : 0)
    }
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly React.Key[]>(
    []
  );

  interface IDatxToDownload {
    parameterId: string;
    fileName: string;
    page: string;
  }
  // Current file being downloaded
  const [datxToDownload, setDatxToDownload] = useState<IDatxToDownload>();

  // downloads the selected file from the server
  const { data: selectedDatxFile, isLoading: loadingDatX } =
    useDownloadSerializedDataQuery(
      datxToDownload?.parameterId
        ? { parameterId: datxToDownload.parameterId }
        : skipToken
    );

  const openOnlineFile = (
    parameterId: string,
    fileName: string,
    page: string
  ) => {
    setDatxToDownload({ parameterId, fileName, page });
  };

  useEffect(() => {
    if (!isUndefined(selectedDatxFile) && datxToDownload?.page === "/graph") {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackDatxAsync({
          filePath: datxToDownload?.fileName ?? "Online file",
          rawData
        })
      );
      dispatch(closeProjectModal());
      navigate(datxToDownload.page);
      setDatxToDownload(undefined);
    }

    if (!isUndefined(selectedDatxFile) && datxToDownload?.page === "/compare") {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackFile({
          filePath: datxToDownload?.fileName ?? "Online file",
          rawData
        })
      );
      dispatch(closeProjectModal());
      navigate(datxToDownload.page);
      setDatxToDownload(undefined);
    }
  }, [selectedDatxFile]);

  const mayOpenFilesInReports = CheckUserRightsAccess("ACB");
  const mayOpenFilesInCompare = CheckUserRightsAccess("ACC");
  const mayOpenProjectFiles =
    project?.userRights && CheckProjectRightsAccess(project?.userRights, "ADJ");

  return (
    <Table
      style={{ minHeight: 400 }}
      dataSource={deviceData}
      loading={loading}
      columns={deviceColumns}
      pagination={{ hideOnSinglePage: true }}
      rowKey={(data) => data.parameterId}
      expandable={{
        onExpandedRowsChange: (key) => setExpandedRowKeys(key),
        expandedRowKeys: expandedRowKeys,
        expandedRowRender: (data) => (
          <>
            <Row align="top" gutter={size.l2}>
              <Col span={8}>
                <Row>
                  <Text strong>{t("RecordingDescription")}</Text>
                </Row>
                <Row>
                  <Paragraph style={{ marginBottom: 0 }}>
                    {data.deviceDescription}
                  </Paragraph>
                </Row>
                {data.deviceDescription === "" && (
                  <Row>
                    <Paragraph style={{ marginBottom: 0 }}>
                      <i>{t("NoDescription")}</i>
                    </Paragraph>
                  </Row>
                )}
              </Col>
              <>
                <Col
                  span={10}
                  style={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                    minHeight: 56
                  }}
                >
                  <Row>
                    <Text strong>{t("FileName")}</Text>
                  </Row>
                  {data.fileName !== "" && data.hasData && (
                    <>
                      <Row>
                        <Paragraph style={{ marginBottom: 0 }}>
                          {data.fileName}
                        </Paragraph>
                      </Row>

                      <Row style={{ paddingTop: size.m1 }}>
                        {mayOpenFilesInReports && mayOpenProjectFiles && (
                          <NormalButtonSmall
                            icon={<LineChartOutlined />}
                            style={{
                              marginRight: size.m1,
                              marginBottom: 6
                            }}
                            disabled={loadingDatX}
                            loading={
                              loadingDatX &&
                              datxToDownload?.parameterId ===
                                data.parameterId &&
                              datxToDownload?.page === "/graph"
                            }
                            onClick={() => {
                              openOnlineFile(
                                data.parameterId,
                                data.fileName!,
                                "/graph"
                              );
                            }}
                          >
                            {t("OpenInReports")}
                          </NormalButtonSmall>
                        )}
                        {mayOpenFilesInCompare && mayOpenProjectFiles && (
                          <NormalButtonSmall
                            icon={<DiffOutlined />}
                            style={{
                              marginRight: size.m1,
                              marginBottom: 6
                            }}
                            disabled={loadingDatX}
                            loading={
                              loadingDatX &&
                              datxToDownload?.parameterId ===
                                data.parameterId &&
                              datxToDownload?.page === "/compare"
                            }
                            onClick={() => {
                              openOnlineFile(
                                data.parameterId,
                                data.fileName!,
                                "/compare"
                              );
                            }}
                          >
                            {t("OpenInCompare")}
                          </NormalButtonSmall>
                        )}
                      </Row>
                    </>
                  )}
                  {(data.fileName === "" || !data.hasData) && (
                    <Row>
                      <Paragraph style={{ marginBottom: 0 }}>
                        <i>{t("NoDataUploaded")}</i>
                      </Paragraph>
                    </Row>
                  )}
                </Col>
                <Col span={6}>
                  <Row>
                    <Text strong>{t("RecordingActions")}</Text>
                  </Row>
                  <Row style={{ paddingTop: size.s1 }}>
                    {data.isActive &&
                      project?.userRights &&
                      CheckProjectRightsAccess(project?.userRights, "ADM") &&
                      data.parameterId.length > 0 &&
                      !isUndefined(data.project?.id) && (
                        <NormalButtonSmall
                          icon={<CheckSquareOutlined />}
                          style={{
                            marginRight: size.m1,
                            marginBottom: 6
                          }}
                          onClick={() => {
                            dispatch(
                              setTransportToFinish({
                                id: data.parameterId,
                                projectId: data.project!.id,
                                deviceName: data.serialNumber,
                                deviceDescription: data.deviceDescription,
                                isActive: false
                              })
                            );
                            dispatch(openFinishTransportModal());
                          }}
                        >
                          {t("FinishTransport")}
                        </NormalButtonSmall>
                      )}
                    {project?.userRights &&
                      CheckProjectRightsAccess(project?.userRights, "ADK") && (
                        <NormalButtonSmall
                          icon={<ExportOutlined />}
                          style={{
                            marginRight: size.m1,
                            marginBottom: 6
                          }}
                          onClick={() => {
                            dispatch(
                              setParameterId({
                                parameterId: data.parameterId,
                                deviceName: data.serialNumber,
                                deviceDescription: data.deviceDescription
                              })
                            );
                            dispatch(openMoveDeviceModal());
                          }}
                        >
                          {t("ChangeProject")}
                        </NormalButtonSmall>
                      )}
                  </Row>
                </Col>
                <Col
                  span={8}
                  style={{
                    borderTop: "1px solid rgba(0, 0, 0, 0.06)",
                    paddingTop: size.s1,
                    minHeight: 56
                  }}
                >
                  <Row>
                    <Text strong>{t("RecordingCreatedDetails")}</Text>
                  </Row>
                  <Row>
                    <Text>
                      {t("Created")}:{" "}
                      {data.created
                        ? dayjs
                            .utc(data.created)
                            .local()
                            .format("YYYY-MM-DD HH:mm")
                        : t("Unknown")}
                    </Text>
                  </Row>
                  <Row>
                    <Text>
                      {t("Creator")}:{" "}
                      {data.creator ? data.creator : t("Unknown")}
                    </Text>
                  </Row>
                </Col>
                <Col
                  span={10}
                  style={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                    borderTop: "1px solid rgba(0, 0, 0, 0.06)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                    paddingTop: size.s1,
                    minHeight: 56
                  }}
                >
                  <Row>
                    <Text strong>{t("LatestUploadDetails")}</Text>
                  </Row>
                  <Row>
                    {data.latestData ? (
                      <Text>
                        {t("Uploaded")}:{" "}
                        {dayjs
                          .utc(data.latestData)
                          .local()
                          .format("YYYY-MM-DD HH:mm")}
                      </Text>
                    ) : (
                      <Text>
                        {t("Uploaded")}: {t("Unknown")}
                      </Text>
                    )}
                  </Row>
                  {data.lastUploadType === "internal_user" && (
                    <Row>
                      <Text>
                        {t("LatestUploadBy")}: {data.lastUploader}{" "}
                        <UserOutlined style={{ marginLeft: size.s1 }} />
                      </Text>
                    </Row>
                  )}
                  {data.lastUploadType === "external_user" && (
                    <Row>
                      <Text>
                        {t("LatestUploadBy")}: {t("ExternalUser")}{" "}
                        <UserSwitchOutlined style={{ marginLeft: size.s1 }} />
                      </Text>
                    </Row>
                  )}
                  {data.lastUploadType === "lte_device" && (
                    <Row>
                      <Text>
                        {t("LatestUploadBy")}: {t("Device")}{" "}
                        <FatIcon style={{ marginLeft: size.s1 }} />
                      </Text>
                    </Row>
                  )}
                  {data.lastUploadType === "" && (
                    <Row>
                      <Text>
                        {t("LatestUploadBy")}: {t("Unknown")}
                      </Text>
                    </Row>
                  )}
                </Col>
              </>
            </Row>
          </>
        )
      }}
    />
  );
};

export default RecordingsTabTable;

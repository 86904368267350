import * as React from "react";
import type { SVGProps } from "react";
const SvgGpsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 620.17 1023.74"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="m385.7 796.68-33.45 67.66c111.58 3.63 197.84 21.57 197.84 43.11 0 24.16-106.65 43.75-238.21 43.75S73.66 931.61 73.66 907.45c0-21.35 83.24-39.12 193.36-43h.81l-33.89-67.71C99.5 809.35 0 854.6 0 908.49c0 63.65 138.83 115.25 310.09 115.25s310.08-51.6 310.08-115.25c0-53.71-100.71-99.02-234.47-111.81"
      fill={props.fill === undefined ? "#409c46" : props.fill}
      fillRule="evenodd"
    />
    <path
      d="M309.36 0C102.4 0-34.36 217 56.78 402.8l252.58 506.6 253.21-523C650 208.16 507.89 0 309.36 0m-.27 372.45A119.48 119.48 0 1 1 428.56 253a119.48 119.48 0 0 1-119.47 119.45"
      fill={props.fill === undefined ? "#409c46" : props.fill}
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGpsIcon;

import { Buffer } from "buffer";
import { KIND_DATXHEADER } from "../../models/FAT100Kinds";

export type DatxHeader = IDatxHeaderV4 | IDatxHeaderV5;

/** Header for DATX v4 */
interface IDatxHeaderV4 {
  fileVersion: number;
  appId: number;
  appVersionMajor: number;
  appVersionMinor: number;
  appBuildMain: number;
  appBuildSub: number;
  fat100Serial: string;
  fat100VersionMajor: number;
  fat100VersionMinor: number;
  fat100BuildMain: number;
  fat100BuildSub: number;
  timezoneId: number;
  configVersion: number;
  password: string;
}

/** Header for DATX v5 */
interface IDatxHeaderV5 {
  fileVersion: number;
  appId: number;
  appVersionMajor: number;
  appVersionMinor: number;
  appBuildMain: number;
  appBuildSub: number;
  fat100Serial: string;
  fat100VersionMajor: number;
  fat100VersionMinor: number;
  fat100BuildMain: number;
  fat100BuildSub: number;
  reserved: number;
  configVersion: number;
  password: string;
}

/** All supported datx versions */
export type DatxVersion = 4 | 5 | 6 | 7;
// DATX 6 was skipped and follows same structure as DATX 5
// DATX 7 header was not changed from DATX 5

function isValidDatxVersion(num: number): num is DatxVersion {
  return num === 4 || num === 5 || num === 6 || num === 7;
}

/**
 * Parses datx header for any supported datx version
 * @param buf The entire datx buffer
 * @throws If unsupported datx version, or error in data
 */

export const parseDatxHeader = (
  buf: Buffer
): { datxHeader: DatxHeader; newPos: number } => {
  let bufPos = 0;
  let expectedLength = 0;

  const hasHeader = buf.readUInt16LE(bufPos);
  if (hasHeader === KIND_DATXHEADER) {
    bufPos += 2;
    expectedLength = buf.readUInt16LE(bufPos);
    bufPos += 2;
  }

  /** fileVersion determines how we will parse the datx header */
  const fileVersion = buf.readUInt16LE(bufPos);

  if (!isValidDatxVersion(fileVersion)) {
    throw new Error(
      `Error. Coud not match datx header against version: ${fileVersion}`
    );
  }

  /** Expected buffer length for datx versions */
  const expLength: Record<DatxVersion, number> = {
    4: expectedLength, //should be 38
    5: expectedLength, //should be 38
    6: expectedLength,
    7: expectedLength
  };

  const outcomes: Record<DatxVersion, (b: Buffer) => DatxHeader> = {
    4: (b) => parseDatxHeaderV4(b),
    5: (b) => parseDatxHeaderV5(b),
    6: (b) => parseDatxHeaderV5(b),
    7: (b) => parseDatxHeaderV5(b)
  };

  const newPos = bufPos + expLength[fileVersion];
  const datxHeader = outcomes[fileVersion](buf.slice(bufPos, newPos));

  return { datxHeader, newPos };
};

const parseDatxHeaderV4 = (buf: Buffer): IDatxHeaderV4 => {
  const expectedLength = 38;

  if (buf.length !== expectedLength) {
    throw new Error(
      `Wrong datx-header length. Expected ${expectedLength} but got: ${buf.length}`
    );
  }

  let bufPos = 0;

  const fileVersion = buf.readUInt16LE(bufPos);
  bufPos += 2;

  const appId = buf.readUInt8(bufPos);
  bufPos += 1;

  const appVersionMajor = buf.readUInt8(bufPos);
  bufPos += 1;

  const appVersionMinor = buf.readUInt8(bufPos);
  bufPos += 1;

  const appBuildMain = buf.readUInt8(bufPos);
  bufPos += 1;

  const appBuildSub = buf.readUInt8(bufPos);
  bufPos += 1;

  let serialString = "";
  for (let i = 0; i < 20; i++) {
    const nextChar = buf.readUInt8(bufPos);
    if (nextChar !== 0) {
      serialString += String.fromCharCode(nextChar);
    }
    bufPos += 1;
  }
  const fat100Serial = serialString;

  const fat100VersionMajor = buf.readUInt8(bufPos);
  bufPos += 1;

  const fat100VersionMinor = buf.readUInt8(bufPos);
  bufPos += 1;

  const fat100BuildMain = buf.readUInt8(bufPos);
  bufPos += 1;

  const fat100BuildSub = buf.readUInt8(bufPos);
  bufPos += 1;

  const timezoneId = buf.readUInt8(bufPos);
  bufPos += 1;

  const configVersion = buf.readUInt16LE(bufPos);
  bufPos += 2;

  let passwordString = "";
  for (let i = 0; i < 4; i++) {
    const nextChar = buf.readUInt8(bufPos);
    if (nextChar !== 0) {
      passwordString += String.fromCharCode(nextChar);
    }
    bufPos += 1;
  }
  const password = passwordString;

  return {
    fileVersion,
    appId,
    appVersionMajor,
    appVersionMinor,
    appBuildMain,
    appBuildSub,
    fat100Serial,
    fat100VersionMajor,
    fat100VersionMinor,
    fat100BuildMain,
    fat100BuildSub,
    timezoneId,
    configVersion,
    password
  };
};

const parseDatxHeaderV5 = (buf: Buffer): IDatxHeaderV5 => {
  const expectedLength = 38;

  if (buf.length !== expectedLength) {
    throw new Error(
      `Wrong datx-header length. Expected ${expectedLength} but got: ${buf.length}`
    );
  }

  let bufPos = 0;

  const fileVersion = buf.readUInt16LE(bufPos);
  bufPos += 2;

  const appId = buf.readUInt8(bufPos);
  bufPos += 1;

  const appVersionMajor = buf.readUInt8(bufPos);
  bufPos += 1;

  const appVersionMinor = buf.readUInt8(bufPos);
  bufPos += 1;

  const appBuildMain = buf.readUInt8(bufPos);
  bufPos += 1;

  const appBuildSub = buf.readUInt8(bufPos);
  bufPos += 1;

  let serialString = "";
  for (let i = 0; i < 20; i++) {
    const nextChar = buf.readUInt8(bufPos);
    if (nextChar !== 0) {
      serialString += String.fromCharCode(nextChar);
    }
    bufPos += 1;
  }
  const fat100Serial = serialString;

  const fat100VersionMajor = buf.readUInt8(bufPos);
  bufPos += 1;

  const fat100VersionMinor = buf.readUInt8(bufPos);
  bufPos += 1;

  const fat100BuildMain = buf.readUInt8(bufPos);
  bufPos += 1;

  const fat100BuildSub = buf.readUInt8(bufPos);
  bufPos += 1;

  const reserved = buf.readUInt8(bufPos);
  bufPos += 1;

  const configVersion = buf.readUInt16LE(bufPos);
  bufPos += 2;

  let passwordString = "";
  for (let i = 0; i < 4; i++) {
    const nextChar = buf.readUInt8(bufPos);
    if (nextChar !== 0) {
      passwordString += String.fromCharCode(nextChar);
    }
    bufPos += 1;
  }
  const password = passwordString;

  return {
    fileVersion,
    appId,
    appVersionMajor,
    appVersionMinor,
    appBuildMain,
    appBuildSub,
    fat100Serial,
    fat100VersionMajor,
    fat100VersionMinor,
    fat100BuildMain,
    fat100BuildSub,
    reserved,
    configVersion,
    password
  };
};

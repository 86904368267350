import { TableCell } from "pdfmake/interfaces";
import { TokenData } from "../sessionHelper";

// Note that these are also translation keys
export type ExportTypes =
  | "AccelerationHistogram"
  | "angle"
  | "DetailedVibrationAnalysis"
  | "extIO"
  | "extTimer"
  | "MinMaxValues"
  | "PrimaryGraph"
  | "QuickReport"
  | "Top10Accelerations"
  | "ActiveParameters"
  | "Invoice"
  | "ParametersPreview"
  | "ProjectReport"
  | "RecordingInformation"
  | "RecordingParameters"
  | "TransportReport"
  | "ProjectReport"
  | "Alarms"
  | "Recordings"
  | "MembersInProject"
  | "ProjectStatus"
  | "Notes";

// The types that should be exported as SVG
export const svgTypes = [
  "AccelerationHistogram",
  "DetailedVibrationAnalysis",
  "PrimaryGraph"
];

export interface DocumentProps {
  documentTitle: string;
  orientation?: Orientation;
  paperFormat?: PaperFormat;
  fileName: string;
}

export interface TransportHeaderData {
  reportType?: ExportTypes; // deprecated but used in print
  projectName?: string;
  hasFilters?: boolean;
  startMethod?: string;
  parametersLoaded?: string;
  scheduledToStart?: string;
  scheduledToEnd?: { time: string | undefined; duration: string | undefined };
  recStart?: string;
  recEnd?: string;
  recEndReason?: string;
  reportStart?: string;
  reportEnd?: string;
  exportedBy?: TokenData;
  deviceId?: string;
}

export interface ProjectHeaderData {
  reportType?: ExportTypes;
  title?: string;
  description?: string;
  startTime?: string;
  endTime?: string;
}

export interface ItemHeaderData {
  itemType: ExportTypes;
  itemTitle: string;
}

export interface TableType {
  headers: TableCell[][];
  rows: TableCell[][];
}
export type Orientation = "landscape" | "portrait";
export type PaperFormat = "A4" | "A3" | "LETTER";

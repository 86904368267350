import IAccParams from "../models/RecordingParameters/IAccParams";
import ITempParams from "../models/RecordingParameters/ITempParams";
import {
  IRecordingParameters,
  IRecordingParametersKeys
} from "../models/RecordingParameters/IRecordingParameters";
import IRecParams from "../models/RecordingParameters/IRecParams";
import { IProgInfo } from "../models/IProgInfo";
import {
  CT_CfgVersion,
  CT_ProgInfo,
  CT_RecParams,
  CT_AccParams,
  CT_TempParams,
  CT_RhParams,
  KIND_RECPARAMS,
  CT_LightSensorParams,
  CT_AccDVATrigLevel,
  CT_AngleParams,
  CX_Schedule,
  CT_AccFilterValues,
  CT_UserInfo,
  CT_ProjectName,
  CT_CompanyId,
  CT_ParameterId,
  CT_ExternalInputs,
  CT_ExternalOutputs,
  CT_ExternalSensor,
  CT_GPS,
  CT_LTE,
  CT_PressureParams
} from "../constants/FAT100-prot-constants";
import IRhParams from "../models/RecordingParameters/IRhParams";
import ILightSensorParams from "../models/RecordingParameters/ILightSensorParams";
import IAngleParams from "../models/RecordingParameters/IAngleParams";
import IPressureParams from "../models/RecordingParameters/IPressureParams";
import {
  disabledScheduleBlock,
  emptyScheduleBlock,
  ScheduleBlock,
  VMRecordingParameters
} from "../models/ViewModelRecordingParameters/VMRecordingParameters";
import { IScheduler } from "../models/IScheduler";
import {
  defaultAccParams,
  defaultAngleParams,
  defaultGpsParams,
  defaultLteParams,
  defaultRhParams,
  defaultStopAfterDuration,
  defaultTempParams,
  disabledAccParams,
  disabledTempParams,
  disabledRhParams,
  disabledAngleParams,
  defaultExternalInputParams,
  defaultExternalOutputParams,
  defaultPressureParams,
  disabledPressureParams
} from "../constants/params-defaults";
import { IAccFilterValues } from "../models/IAccFilterValues";
import { getAppId, getAppVersion, getCfgVersion } from "./versionHelper";
import { vmRecordingParameters2DataModel } from "./dataModelHelper";
import { invalidRecordingParametersErrorMessage } from "../utils/invalidRecordingParametersMessage";
import { parxHeaderLength } from "../constants/fat100SpecDefaults";
import { GeneralSystemInfo } from "../models/ISystemInfo";
import { interpretSystemInfoHasFeatures } from "./parsers/parseSystemInfoHelper";
import { IParxHeader } from "../models/PARX/IParxHeader";
import { IParxFile } from "../models/PARX/IParxFile";
import IExternalInputsParams from "../models/RecordingParameters/IExternalInputsParams";
import IExternalOutputsParams from "../models/RecordingParameters/IExternalOutputsParams";
import IExternalSensorParams from "../models/RecordingParameters/IExternalSensorParams";
import IGPSParams from "../models/RecordingParameters/IGPSParams";
import ILTEParams from "../models/RecordingParameters/ILTEParams";
import { KIND_PARXHEADER } from "../models/FAT100Kinds";
import { Buffer } from "buffer";
import { saveParx } from "./fileHelperUniversal";
import { isIExternalRhTempParams } from "../models/RecordingParameters/IExternalRhTempParams";

interface IConfig {
  [key: string]: any;
}

const isProgInfo = (object: any): object is IProgInfo => {
  if (
    (object as IProgInfo).appVersionMajor &&
    (object as IProgInfo).appVersionMinor &&
    (object as IProgInfo).appBuildMain &&
    (object as IProgInfo).appBuildSub &&
    (object as IProgInfo).timezoneId &&
    (object as IProgInfo).appId
  ) {
    return true;
  }
  return false;
};

const isRecParams = (object: any): object is IRecParams => {
  if (
    (object as IRecParams).recMode &&
    (object as IRecParams).start &&
    (object as IRecParams).end
  ) {
    return true;
  }
  return false;
};

/** Lookup table from ct_accFilterValue to Hz */
export const accFilterCtValueToHzLookup: Record<number, number> = {
  0: 800,
  1: 10,
  2: 20,
  3: 40,
  4: 80,
  5: 90,
  6: 120,
  7: 160,
  8: 250,
  9: 320,
  10: 29.5,
  11: 8.8
};

/** Ssi types by name */
type SsiType =
  | "Acc"
  | "Temp"
  | "Rh"
  | "Angle"
  | "Pressure"
  | "Light"
  | "Gps"
  | "Lte"
  | "Bluetooth"
  | "DevStatus"
  | "DevHealth";
/** Ssi name to id lookup */
const ssiLookup: Record<SsiType, number> = {
  Acc: 0x00,
  Temp: 0x01,
  Rh: 0x02,
  Angle: 0x03,
  Pressure: 0x04,
  Light: 0x05,
  Gps: 0x06,
  Lte: 0x07,
  Bluetooth: 0x08,
  DevStatus: 0x09,
  DevHealth: 0x0a
};

let _buffer = new ArrayBuffer(1030 * 10); // size in bytes
let _view = new DataView(_buffer);
//NOTE: Start at position 4 since KIND_RECPARAMS and LENGTH will be calculated on the final config.
let _viewpos = 4;

export const getConfig = (path: string) => {
  console.log("whops! not implemented");
};

/**
 * Create a pair of starting parameters that can be used as a base when creating fresh parameters
 */
export const createBaseParams = (): VMRecordingParameters => ({
  CfgVersion: getCfgVersion(),
  ProgInfo: getProgInfo(),
  RecParams: {
    startRecordingType: "directly",
    endRecordingType: "date",
    stopAfterDuration: defaultStopAfterDuration
  },
  AccParams: defaultAccParams,
  TempParams: defaultTempParams,
  PressureParams: defaultPressureParams,
  RhParams: defaultRhParams,
  AngleParams: defaultAngleParams,
  LteParams: defaultLteParams,
  GpsParams: defaultGpsParams,
  ExternalInputParams: defaultExternalInputParams,
  ExternalOutputParams: defaultExternalOutputParams,
  ExternalSensorParams: [],
  scheduleBlocks: [emptyScheduleBlock]
});

/**
 * Creates disabled parameters as a base for incomplete parameter files
 */
export const createEmptyParams = (): VMRecordingParameters => ({
  CfgVersion: getCfgVersion(),
  ProgInfo: getProgInfo(),
  RecParams: {
    startRecordingType: "directly",
    endRecordingType: "date",
    stopAfterDuration: defaultStopAfterDuration
  },
  AccParams: disabledAccParams,
  TempParams: disabledTempParams,
  PressureParams: disabledPressureParams,
  RhParams: disabledRhParams,
  AngleParams: disabledAngleParams,
  LteParams: defaultLteParams,
  GpsParams: defaultGpsParams,
  ExternalInputParams: defaultExternalInputParams,
  ExternalOutputParams: defaultExternalOutputParams,
  ExternalSensorParams: [],
  scheduleBlocks: [disabledScheduleBlock]
});

const getProgInfo = (): IProgInfo => ({
  ...getAppVersion(),
  appId: getAppId(),
  timezoneId: 1
});

//extend this
export const validateConfig = (config: IRecordingParameters) => {
  console.log("recieved the following config: ", config);
  if (!isProgInfo(config.ProgInfo)) return false;
  if (!isRecParams(config.RecParams)) return false;

  // if (!sciOk(config)) return false;

  return true;
};

/**
 * Packages a parx into a buffer
 * @param parx
 */
export const packParx = (parx: IParxFile) => {
  const header = packParxHeader(parx.header);
  const content = packRecordingParameters(parx.recordingParameters);

  //is this bad performance
  const finalBuf = Buffer.concat([Buffer.from(header), Buffer.from(content)]);

  return finalBuf;
};

const packParxHeader = (header: IParxHeader) => {
  const headerContentLength = parxHeaderLength - 4;
  const buf = new ArrayBuffer(parxHeaderLength);
  const view = new DataView(buf);
  let viewPos = 0;

  view.setUint16(viewPos, KIND_PARXHEADER, true);
  viewPos += 2;

  view.setUint16(viewPos, headerContentLength, true);
  viewPos += 2;

  view.setUint16(viewPos, header.fileVersion, true);
  viewPos += 2;

  view.setUint8(viewPos, header.appId);
  viewPos += 1;

  view.setUint8(viewPos, header.appVersionMajor);
  viewPos += 1;

  view.setUint8(viewPos, header.appVersionMinor);
  viewPos += 1;

  view.setUint8(viewPos, header.appBuildMain);
  viewPos += 1;

  view.setUint8(viewPos, header.appBuildSub);
  viewPos += 1;

  view.setUint8(viewPos, header.timezoneId);
  viewPos += 1;

  view.setUint16(viewPos, header.configVersion, true);
  viewPos += 2;

  return buf;
};

//TODO: This should prob be able to throw errors
/**
 * Packeges KIND_RECPARAMS into an ArrayBuffer and returns it. After that it can be sent directly to FAT100 or be saved to disk.
 * @param params FAT100 RecParams
 * @throws Unknown CT-type
 */
export const packRecordingParameters = (params: IRecordingParameters) => {
  _buffer = new ArrayBuffer(1030 * 10); // size in bytes
  _view = new DataView(_buffer);
  // Start at position 4 since KIND_RECPARAMS and LENGTH will be calculated on the final config.
  _viewpos = 4;

  const packRecParamsFunc = getRecParamsPackFuncsMap();
  //make sure schedulers is put last
  Object.entries(params).forEach(([key, val]) => {
    const packFunc = packRecParamsFunc.get(key as keyof IRecordingParameters);
    try {
      packFunc!(val);
    } catch (e) {
      // saveErrorToLog(errorString + key);
      throw new Error(`This function does not support RecParams-key: ${key}`);
    }
  });

  packKindForRecordingParameters();

  return _buffer.slice(0, _viewpos);
};

/** A hashmap where the keys is the the fields in the IRecordingParameters
 * interface and the value is a function that can handle packing that ct-field
 * */
const getRecParamsPackFuncsMap = () =>
  new Map<IRecordingParametersKeys, (data: any) => void>([
    ["CfgVersion", packCfgVersion],
    ["ProgInfo", packProgInfo],
    ["RecParams", packRecParams],
    ["AccParams", packAccParams],
    ["AccDVATrigLevel", packAccDVATrigLevel],
    ["TempParams", packTempParams],
    ["RhParams", packRhParams],
    ["AngleParams", packAngleParams],
    ["PressureParams", packPressureParams],
    ["ExternalInputs", packExternalInputsParams],
    ["ExternalOutputs", packExternalOutputsParams],
    ["LightSensorParams", packLightSensorParams],
    ["GPS", packGPSParams],
    ["LTE", packLTEParams],
    ["UserInfo", packUserInfoParams],
    ["ExternalSensors", packExternalSensorParams],
    ["ProjectName", packProjectNameParams],
    ["CompanyId", packCompanyIdParams],
    ["ParameterId", packParameterIdParams],
    ["AccFilterValues", packAccFilterValues],
    ["SRD", packSchedulers]
  ]);

const packKindForRecordingParameters = () => {
  _view.setUint16(0, KIND_RECPARAMS, true);
  // note: the length of the config is how far the buffer has been written, minus this message (and length)
  _view.setUint16(2, _viewpos - 4, true);
};

const packCfgVersion = (data: number) => {
  _view.setUint8(_viewpos, CT_CfgVersion);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 2);
  _viewpos += 1;

  _view.setUint16(_viewpos, data, true);
  _viewpos += 2;
};

const packProgInfo = (progInfo: IProgInfo) => {
  _view.setUint8(_viewpos, CT_ProgInfo);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 6);
  _viewpos += 1;

  _view.setUint8(_viewpos, progInfo.appVersionMajor);
  _viewpos += 1;

  _view.setUint8(_viewpos, progInfo.appVersionMinor);
  _viewpos += 1;

  _view.setUint8(_viewpos, progInfo.appBuildMain);
  _viewpos += 1;

  _view.setUint8(_viewpos, progInfo.appBuildSub);
  _viewpos += 1;

  _view.setUint8(_viewpos, progInfo.timezoneId);
  _viewpos += 1;

  _view.setUint8(_viewpos, progInfo.appId);
  _viewpos += 1;
};

const packRecParams = (data: IRecParams) => {
  _view.setUint8(_viewpos, CT_RecParams);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 14);
  _viewpos += 1;
  _view.setUint16(_viewpos, data.recMode, true);
  _viewpos += 2;

  _view.setUint8(_viewpos, data.start.year);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.start.month);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.start.day);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.start.hour);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.start.minute);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.start.second);
  _viewpos += 1;

  _view.setUint8(_viewpos, data.end.year);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.end.month);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.end.day);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.end.hour);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.end.minute);
  _viewpos += 1;
  _view.setUint8(_viewpos, data.end.second);
  _viewpos += 1;
};

//TODO: Waiting for decission regarding how this should be calculated
const packAccParams = (params: IAccParams) => {
  _view.setUint8(_viewpos, CT_AccParams);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 18);
  _viewpos += 1;

  //måste tydligen inte vara i ordning (gjorde ingen skillnad)
  _view.setInt16(_viewpos, params.Xreg, true);
  _viewpos += 2;
  _view.setInt16(_viewpos, params.Yreg, true);
  _viewpos += 2;
  _view.setInt16(_viewpos, params.Zreg, true);
  _viewpos += 2;

  _view.setInt16(_viewpos, params.Xalarm, true);
  _viewpos += 2;
  _view.setInt16(_viewpos, params.Yalarm, true);
  _viewpos += 2;
  _view.setInt16(_viewpos, params.Zalarm, true);
  _viewpos += 2;

  _view.setInt16(_viewpos, params.Xms, true);
  _viewpos += 2;
  _view.setInt16(_viewpos, params.Yms, true);
  _viewpos += 2;
  _view.setInt16(_viewpos, params.Zms, true);
  _viewpos += 2;
};

//Not tested
const packAccDVATrigLevel = (value: number) => {
  _view.setUint8(_viewpos, CT_AccDVATrigLevel);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 2);
  _viewpos += 1;
  //data
  _view.setUint16(_viewpos, value, true);
  _viewpos += 2;
};

//should be signed
const packTempParams = (params: ITempParams) => {
  _view.setUint8(_viewpos, CT_TempParams);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 4);
  _viewpos += 1;

  //Is this the right amount of bytes?
  Object.values(params).forEach((d) => {
    //the value is multiplied by 100 so that we can use 2 deciamals precision
    //without having to use a float
    const value = Number(d) * 100;
    //Normal int since temp can be negative
    _view.setInt16(_viewpos, value, true);
    _viewpos += 2;
  });
};

const packRhParams = (params: IRhParams) => {
  _view.setUint8(_viewpos, CT_RhParams);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 4);
  _viewpos += 1;

  Object.values(params).forEach((d) => {
    //the value is multiplied by 100 so that we can use 2 deciamals precision
    //without having to use a float
    const value = Number(d) * 100;
    //Normal int since temp can be negative
    _view.setInt16(_viewpos, value, true);
    _viewpos += 2;
  });
};

const packAngleParams = (params: IAngleParams) => {
  _view.setUint8(_viewpos, CT_AngleParams);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 12);
  _viewpos += 1;

  _view.setUint16(_viewpos, params.xAlarmLevel, true);
  _viewpos += 2;

  _view.setUint16(_viewpos, params.yAlarmLevel, true);
  _viewpos += 2;

  _view.setUint16(_viewpos, params.zAlarmLevel, true);
  _viewpos += 2;

  _view.setInt16(_viewpos, params.xOffset ?? 0, true);
  _viewpos += 2;

  _view.setInt16(_viewpos, params.yOffset ?? 0, true);
  _viewpos += 2;

  _view.setInt16(_viewpos, params.zOffset ?? 0, true);
  _viewpos += 2;
};

//not tested
const packPressureParams = (params: IPressureParams) => {
  _view.setUint8(_viewpos, CT_PressureParams);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 10);
  _viewpos += 1;

  _view.setUint16(_viewpos, params.lowAlarm, true);
  _viewpos += 2;

  _view.setUint16(_viewpos, params.highAlarm, true);
  _viewpos += 2;

  // 3 decimals are stored as 1000 times the actual value
  const slopeValue = Math.round(params.slopeValue * 1000);
  _view.setInt32(_viewpos, slopeValue, true);
  _viewpos += 4;

  // Only 1 decimal is used and is stored 100 times larger than the actual value
  const tempValue = Math.round(params.tempValue * 100);
  _view.setInt16(_viewpos, tempValue, true);
  _viewpos += 2;
};

// New in config version 7
const packExternalInputsParams = (params: IExternalInputsParams[]) => {
  params.forEach((param) => {
    _view.setUint8(_viewpos, CT_ExternalInputs);
    _viewpos += 1;

    //dataLen
    _view.setUint8(_viewpos, 32);
    _viewpos += 1;

    _view.setUint8(_viewpos, param.inputBit);
    _viewpos += 1;

    _view.setUint8(_viewpos, param.onChange);
    _viewpos += 1;

    _view.setUint16(_viewpos, param.alsoRecord, true);
    _viewpos += 2;

    _view.setUint16(_viewpos, param.alarmOutputBits, true);
    _viewpos += 2;

    _view.setUint16(_viewpos, param.alarmInputBits, true);
    _viewpos += 2;

    const blockLength = 24;
    const termByte = "\0";
    // Check if the description fits
    if (param.description.length > blockLength - termByte.length) {
      throw new Error("description is too long");
    }
    // Write description character by character
    [...param.description].forEach((x) => {
      _view.setUint8(_viewpos, x.charCodeAt(0));
      _viewpos += 1;
    });
    // Add a terminating byte
    _view.setUint8(_viewpos, termByte.charCodeAt(0));
    _viewpos += 1;

    // Move to the next block
    const restLen = blockLength - param.description.length - termByte.length;
    _viewpos += restLen;
  });
};

// New in config version 7
const packExternalOutputsParams = (params: IExternalOutputsParams[]) => {
  params.forEach((param) => {
    _view.setUint8(_viewpos, CT_ExternalOutputs);
    _viewpos += 1;

    _view.setUint8(_viewpos, 48);
    _viewpos += 1;

    _view.setUint8(_viewpos, param.outputBit);
    _viewpos += 1;

    _view.setUint8(_viewpos, param.onChange);
    _viewpos += 1;

    // Extra unused byte
    _viewpos += 1;

    _view.setUint8(_viewpos, param.initialState);
    _viewpos += 1;

    _view.setUint16(_viewpos, param.alsoRecord, true);
    _viewpos += 2;

    _view.setUint16(_viewpos, param.alarmOutputBits, true);
    _viewpos += 2;

    _view.setUint16(_viewpos, param.alarmInputBits, true);
    _viewpos += 2;

    const blockLength = 24;
    const termByte = "\0";
    // Check if the description fits
    if (param.description.length > blockLength - termByte.length) {
      throw new Error("description is too long");
    }
    // Write description character by character
    [...param.description].forEach((x) => {
      _view.setUint8(_viewpos, x.charCodeAt(0));
      _viewpos += 1;
    });
    // Add a terminating byte
    _view.setUint8(_viewpos, termByte.charCodeAt(0));
    _viewpos += 1;

    // Move to the next block
    const restLen = blockLength - param.description.length - termByte.length;
    _viewpos += restLen;
  });
};

// New in config version 7
const packGPSParams = (params: IGPSParams) => {
  _view.setUint8(_viewpos, CT_GPS);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 2);
  _viewpos += 1;

  _view.setUint16(_viewpos, params.storeSpeed, true);
  _viewpos += 2;
};

// New in config version 7
const packLTEParams = (params: ILTEParams) => {
  _view.setUint8(_viewpos, CT_LTE);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 2);
  _viewpos += 1;
  let LTEbits = params.GPSRequired;
  LTEbits |= params.Enable2G << 8;
  LTEbits |= params.Enable3G << 9;
  LTEbits |= params.Enable4G << 10;
  LTEbits |= params.Enable5G << 11;
  LTEbits |= params.Enable6G << 12;
  _view.setUint16(_viewpos, LTEbits, true);
  _viewpos += 2;
};

// New in config version 7
const packExternalSensorParams = (params: IExternalSensorParams[]) => {
  params.forEach((param) => {
    if (isIExternalRhTempParams(param.sensorConfig)) {
      _view.setUint8(_viewpos, CT_ExternalSensor);
      _viewpos += 1;

      // dataLen in CT header
      _view.setUint8(_viewpos, 34);
      _viewpos += 1;

      _view.setUint16(_viewpos, param.sensorTypeId, true);
      _viewpos += 2;

      const termByte = "\0";

      // Write sensor name character by character.
      let blockLength = 24;
      if (param.sensorName.length > blockLength) {
        throw new Error("Sensor name is too long");
      }
      [...param.sensorName].forEach((x) => {
        _view.setUint8(_viewpos, x.charCodeAt(0));
        _viewpos += 1;
      });
      // Terminating byte
      _view.setUint8(_viewpos, termByte.charCodeAt(0));
      _viewpos += 1;
      // Move to the next block
      let restLen = blockLength - param.sensorName.length - termByte.length;
      _viewpos += restLen;

      // Pack sensor config data
      const rhMin = Math.round(param.sensorConfig.rhMin * 100);
      _view.setInt16(_viewpos, rhMin, true);
      _viewpos += 2;

      const rhMax = Math.round(param.sensorConfig.rhMax * 100);
      _view.setInt16(_viewpos, rhMax, true);
      _viewpos += 2;

      const tempMin = Math.round(param.sensorConfig.tempMin * 100);
      _view.setInt16(_viewpos, tempMin, true);
      _viewpos += 2;

      const tempMax = Math.round(param.sensorConfig.tempMax * 100);
      _view.setInt16(_viewpos, tempMax, true);
      _viewpos += 2;
    }
  });
};

//not tested
const packLightSensorParams = (params: ILightSensorParams) => {
  _view.setUint8(_viewpos, CT_LightSensorParams);
  _viewpos += 1;
  //dataLen
  _view.setUint8(_viewpos, 2);
  _viewpos += 1;

  _view.setUint16(_viewpos, params.alarmLevel, true);
  _viewpos += 2;
};

const packAccFilterValues = (params: IAccFilterValues) => {
  _view.setUint8(_viewpos, CT_AccFilterValues);
  _viewpos += 1;

  //datalen
  _view.setUint8(_viewpos, 4);
  _viewpos += 1;

  _view.setUint16(_viewpos, params.value1, true);
  _viewpos += 2;

  _view.setUint16(_viewpos, params.value2, true);
  _viewpos += 2;
};

const packUserInfoParams = (info: string) => {
  _view.setUint8(_viewpos, CT_UserInfo);
  _viewpos += 1;

  const blockLength = 250;
  const termByte = "\0";

  if (info.length > blockLength - termByte.length) {
    throw new Error("user info length more than max length");
  }

  //datalen
  _view.setUint8(_viewpos, blockLength);
  _viewpos += 1;
  [...info].forEach((x) => {
    _view.setUint8(_viewpos, x.charCodeAt(0));
    _viewpos += 1;
  });

  _view.setUint8(_viewpos, termByte.charCodeAt(0));
  _viewpos += 1;

  const restLen = blockLength - info.length - termByte.length;
  _viewpos += restLen;
};

const packProjectNameParams = (name: string) => {
  _view.setUint8(_viewpos, CT_ProjectName);
  _viewpos += 1;

  const blockLength = 64;
  const termByte = "\0";

  if (name.length > blockLength - termByte.length) {
    throw new Error("project name length more than max length");
  }

  //datalen
  _view.setUint8(_viewpos, blockLength);
  _viewpos += 1;
  [...name].forEach((x) => {
    _view.setUint8(_viewpos, x.charCodeAt(0));
    _viewpos += 1;
  });

  _view.setUint8(_viewpos, termByte.charCodeAt(0));
  _viewpos += 1;

  const restLen = blockLength - name.length - termByte.length;
  _viewpos += restLen;
};

const packCompanyIdParams = (companyId: string) => {
  _view.setUint8(_viewpos, CT_CompanyId);
  _viewpos += 1;

  const blockLength = 40;

  if (companyId.length > blockLength) {
    throw new Error("Company Id longer than max length");
  }

  //datalen
  _view.setUint8(_viewpos, blockLength);
  _viewpos += 1;
  [...companyId].forEach((x) => {
    _view.setUint8(_viewpos, x.charCodeAt(0));
    _viewpos += 1;
  });

  const restLen = blockLength - companyId.length;
  _viewpos += restLen;
};

const packParameterIdParams = (parameterId: string) => {
  _view.setUint8(_viewpos, CT_ParameterId);
  _viewpos += 1;

  const blockLength = 30;

  if (parameterId.length > blockLength) {
    throw new Error("Parameter Id longer than max length");
  }

  //datalen
  _view.setUint8(_viewpos, blockLength);
  _viewpos += 1;
  [...parameterId].forEach((x) => {
    _view.setUint8(_viewpos, x.charCodeAt(0));
    _viewpos += 1;
  });

  const restLen = blockLength - parameterId.length;
  _viewpos += restLen;
};

const packSchedulers = (params: IScheduler[]) => {
  _view.setUint8(_viewpos, CX_Schedule);
  _viewpos += 1;

  //reserved
  _view.setUint8(_viewpos, 0);
  _viewpos += 1;

  let totalLen = 0;
  params.forEach((scheduler) => {
    /*
      1 byte for sensorId
      1 byte for nr of schedules
      42 bytes for each schedule block
    */
    totalLen += 1 + 1 + 42 * scheduler.SRD.length;
  });
  _view.setUint16(_viewpos, totalLen, true);
  _viewpos += 2;

  /** Loop through each sensor schedule */
  params.forEach((scheduler) => {
    _view.setUint8(_viewpos, scheduler.sensorId);
    _viewpos += 1;
    _view.setUint8(_viewpos, scheduler.SRD.length);
    _viewpos += 1;

    scheduler.SRD.forEach((SRD) => {
      _view.setUint8(_viewpos, SRD.start.year);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.start.month);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.start.day);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.start.hour);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.start.minute);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.start.second);
      _viewpos += 1;

      _view.setUint8(_viewpos, SRD.end.year);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.end.month);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.end.day);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.end.hour);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.end.minute);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.end.second);
      _viewpos += 1;

      _view.setUint32(_viewpos, SRD.onPeriod, true);
      _viewpos += 4;

      _view.setUint32(_viewpos, SRD.extAlsoRecord, true);
      _viewpos += 4;

      _view.setUint32(_viewpos, SRD.measureInterval, true);
      _viewpos += 4;

      _view.setUint32(_viewpos, SRD.value32, true);
      _viewpos += 4;

      _view.setUint16(_viewpos, SRD.ExtSensTypeID, true);
      _viewpos += 2;

      _view.setUint16(_viewpos, SRD.FuncCtrl, true);
      _viewpos += 2;

      _view.setUint8(_viewpos, SRD.IgnoreCountLTE);
      _viewpos += 1;
      _view.setUint8(_viewpos, SRD.IgnoreCountGPS);
      _viewpos += 1;

      _view.setUint16(_viewpos, SRD.AlsoRecord, true);
      _viewpos += 2;

      _view.setUint16(_viewpos, SRD.AlarmOutputBits, true);
      _viewpos += 2;

      _view.setUint16(_viewpos, SRD.AlarmInputBits, true);
      _viewpos += 2;

      // Last 2 bytes are reserved for future use
      _viewpos += 2;
    });
  });
};

export interface IValidatedPackedRecordingParameters {
  isOk: boolean;
  isCfgType: boolean;
  hasRightLen: boolean;
  hasCfgVersion: boolean;
  hasProgInfo: boolean;
  hasRecParams: boolean;
}
/**
 * This function performs validation on Recording Parameters. It should be used before
 * saving/opening a parx-file and before downloading to FAT100
 * @param RecordingParameters packed KIND_RECPARAMS
 */
export const validatePackedRecordingParameters = (
  RecordingParameters: Buffer
): IValidatedPackedRecordingParameters => {
  let res = {} as IValidatedPackedRecordingParameters;
  const buf = Buffer.from(RecordingParameters);

  // Config is too short to be viable
  if (buf.length < 50) {
    res.isOk = false;
    return res;
  }

  res.isCfgType = buf.readUInt16LE(0) === KIND_RECPARAMS;
  res.hasRightLen = buf.readUInt16LE(2) === buf.length - 4;
  res.hasCfgVersion = buf.readUInt8(4) === CT_CfgVersion;
  res.hasProgInfo = buf.readUInt8(8) === CT_ProgInfo;
  // Handle older parx files where license key is not removed
  res.hasRecParams =
    buf.readUInt8(16) === CT_RecParams || buf.readUInt8(36) === CT_RecParams;

  res.isOk =
    res.isCfgType &&
    res.hasRightLen &&
    res.hasCfgVersion &&
    res.hasProgInfo &&
    res.hasRecParams;

  return res;
};

export const validatePackedParx = (
  parx: Buffer
): IValidatedPackedRecordingParameters => {
  let headerLength = parxHeaderLength - 4;
  if (parx.readUInt16LE(0) === KIND_PARXHEADER) {
    headerLength += 4;
  }
  const recordingParametersSlice = parx.slice(headerLength);

  const res = validatePackedRecordingParameters(recordingParametersSlice);
  return res;
};

/**
 * Creates a parx from recording parameters
 * @param recordingParameters
 */
export const createParx = (
  recordingParameters: IRecordingParameters
): IParxFile => {
  const { ProgInfo } = recordingParameters;
  const header = createParxHeader(ProgInfo);

  return { header, recordingParameters };
};

export const createParxHeader = (progInfo: IProgInfo): IParxHeader => {
  const {
    appId,
    appVersionMajor,
    appVersionMinor,
    appBuildMain,
    appBuildSub,
    timezoneId
  } = progInfo;

  const fileVersion = 2;
  const configVersion = 8;

  return {
    fileVersion,
    appId,
    appVersionMajor,
    appVersionMinor,
    appBuildMain,
    appBuildSub,
    timezoneId,
    configVersion
  };
};

/**
 * Helper function that validates Recording Parameters and save them as a parx-file. If validation doesen't go through an error message will be shown on the screen
 * @param params
 */
export const saveAsParx = (
  params: VMRecordingParameters,
  targetDevice?: GeneralSystemInfo
) => {
  const recordingParameters = vmRecordingParameters2DataModel(
    params,
    targetDevice
  );
  const parx = createParx(recordingParameters);
  const packed = packParx(parx);
  const validationRes = validatePackedParx(packed);
  const isValid = validationRes.isOk;
  isValid ? saveParx(packed) : invalidRecordingParametersErrorMessage();
};

/**
 * Turns partial recording parameters view model into actual recording parameters view model. Falls back on defaults on fields that are undefined.
 * @param rp RecordingParameters
 */
export const vmRecordingParametersFromPartial = (
  rp: Partial<VMRecordingParameters> | undefined
): VMRecordingParameters => {
  /** Fallback recording parameters */
  const fbrp = createEmptyParams();

  const CfgVersion = rp?.CfgVersion ?? fbrp.CfgVersion;
  const ProgInfo = rp?.ProgInfo ?? fbrp.ProgInfo;
  const RecParams = rp?.RecParams ?? fbrp.RecParams;
  const AccParams = rp?.AccParams ?? fbrp.AccParams;
  const TempParams = rp?.TempParams ?? fbrp.TempParams;
  const PressureParams = rp?.PressureParams ?? fbrp.PressureParams;
  const RhParams = rp?.RhParams ?? fbrp.RhParams;
  const AngleParams = rp?.AngleParams ?? fbrp.AngleParams;
  const UserInfo = rp?.UserInfo ?? undefined;
  const ProjectName = rp?.ProjectName ?? undefined;
  const CompanyId = rp?.CompanyId ?? undefined;
  const ParameterId = rp?.ParameterId ?? undefined;
  const LteParams = rp?.LteParams ?? fbrp.LteParams;
  const GpsParams = rp?.GpsParams ?? fbrp.GpsParams;
  const ExternalInputParams =
    rp?.ExternalInputParams ?? fbrp.ExternalInputParams;
  const ExternalOutputParams =
    rp?.ExternalOutputParams ?? fbrp.ExternalOutputParams;
  const ExternalSensorParams =
    rp?.ExternalSensorParams ?? fbrp.ExternalSensorParams;
  const scheduleBlocks = rp?.scheduleBlocks ?? fbrp.scheduleBlocks;

  return {
    CfgVersion,
    ProgInfo,
    RecParams,
    AccParams,
    TempParams,
    PressureParams,
    RhParams,
    AngleParams,
    UserInfo,
    ProjectName,
    CompanyId,
    ParameterId,
    LteParams,
    GpsParams,
    ExternalInputParams,
    ExternalOutputParams,
    ExternalSensorParams,
    scheduleBlocks
  };
};

/**
 * Get acceleration max g depending on active sensors
 * @param systemInfo
 */
export const getAccMaxG = (systemInfo: GeneralSystemInfo) => {
  const features = interpretSystemInfoHasFeatures(systemInfo.hasFeatures);
  const hasExtraSensor = features.DF_ACCEXTRA;

  return hasExtraSensor ? systemInfo.accExtraMaxG : systemInfo.accStdMaxG;
};

/** Sum of the duration of all schedule blocks in hours */
export const getScheduleDuration = (scheduleBlocks: ScheduleBlock[]) => {
  let duration = 0;
  scheduleBlocks.forEach((block) => {
    duration += block.blockDuration;
  });
  return duration;
};

/** Get days from a number of hours */
export const getDays = (hours: number) => {
  return Math.floor(hours / 24);
};
/** Get remove days from a number of hours */
export const getHours = (hours: number) => {
  return Math.round((hours % 24) * 100) / 100;
};

/** Get hours from days, hours and minutes */
export const getHoursFromDaysHoursMinutes = (
  days: number,
  hours: number,
  minutes: number
) => {
  return days * 24 + hours + minutes / 60;
};

/** Returns the last 4 bits of the sensor type id */
export const getSensorNrFromId = (sensorTypeId: number) => {
  return sensorTypeId & 0x000f;
};

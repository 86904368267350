import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction
} from "@reduxjs/toolkit";
import paramsPageReducer, { ParamsPageState } from "./paramsPageSlice";
import openDatxReducer, { IOpenDatxFiles } from "./openDatxSlice";
import openParxReducer, { StateOpenParx } from "./openParxSlice";
import settingsReducer, { StateSettings } from "./settingsSlice";
import navigationReducer, { NavigationState } from "./navigationSlice";
import modalsReducer, { ModalState } from "./modalsSlice";
import licenseStoreReducer, { LicenseStoreState } from "./licenseStoreSlice";
import sessionReducer, { SessionState } from "./sessionSlice";
import licensePriceReducer, { LicensePriceState } from "./licensePriceSlice";
import updatesReducer, { UpdatesState } from "./updatesSlice";
import persistantStateReducer, {
  initialState,
  PeristantStateState,
  reHydrateState
} from "./persistantStateSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { cargologRestApi } from "./cargologRestApi";
import compareGraphFilesReducer, {
  ICompareGraphFiles
} from "./compareGraphsSlice";
import helpReducer, { StateHelp } from "./helpSlice";
import projectReducer, { ProjectState } from "./projectSlice";

/** Combine multiple reducer functions into a single reducer function. */
const rootReducer = combineReducers({
  compareGraphFiles: compareGraphFilesReducer,
  help: helpReducer,
  licensePrice: licensePriceReducer,
  licenseStore: licenseStoreReducer,
  modals: modalsReducer,
  navigation: navigationReducer,
  openDatx: openDatxReducer,
  openParx: openParxReducer,
  paramsPage: paramsPageReducer,
  persistantState: persistantStateReducer,
  projects: projectReducer,
  settings: settingsReducer,
  session: sessionReducer,
  updates: updatesReducer,
  [cargologRestApi.reducerPath]: cargologRestApi.reducer
});

const migrations = [
  (state: any) => {
    // Clear out entire state
    return { persistantState: initialState };
  },
  (state: any) => {
    // Update persistant with new fields from initial state
    const newPersistantState = { ...initialState, ...state.persistantState };
    return { ...state, persistantState: newPersistantState };
  },
  (state: any) => {
    // Fix devices now being synced with the server
    const { myDevices, ...persistantState } = state.persistantState;
    const newPersistantState = {
      ...initialState,
      ...persistantState,
      devices: initialState.devices
    };
    return { ...state, persistantState: newPersistantState };
  },
  (state: any) => {
    // Replace globalGraphScale with initialState.globalGraphScale
    const { globalGraphScale, ...persistantState } = state.persistantState;
    const newPersistantState = {
      ...persistantState,
      globalGraphScale: initialState.globalGraphScale
    };
    return { ...state, persistantState: newPersistantState };
  },
  (state: any) => {
    // Remove recentOnlineFiles and recentCompareGraphFiles
    // Clear out recentDatxFiles and recentParxFiles
    const { recentOnlineFiles, recentCompareGraphFiles, ...persistantState } =
      state.persistantState;
    const newPersistantState = {
      ...persistantState,
      recentDatxFiles: [],
      recentParxFiles: []
    };
    return { ...state, persistantState: newPersistantState };
  }
];

const persistConfig = {
  key: "root",
  version: 5, // Target version of the state counting from 1
  storage,
  whitelist: ["persistantState"],
  transforms: [reHydrateState],
  migrate: (state: any, version: number) => {
    let migratedState = state ?? initialState;
    // version = next index in migrations to run
    const firstMigration = state?._persist?.version || 0;
    // index counter starts from 0 version starts from 1
    for (let i = firstMigration; i < version; i++) {
      if (i in migrations) {
        migratedState = migrations[i](migratedState);
        console.log("Migrated state to version", i + 1);
      }
    }
    return Promise.resolve(migratedState);
  }
};
const persistedReducer = persistReducer(persistConfig, rootReducer as any);

/* Create a Redux store instance. */
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
      // Removed because check took too much time on large state objects (opening files)
      // serializableCheck: {
      //   //redux persist actions
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat(cargologRestApi.middleware)
});

export default store;

export type StoreType = typeof store;

export type AppDispatch = typeof store.dispatch;

export interface StoreApi {
  compareGraphFiles: ICompareGraphFiles;
  help: StateHelp;
  licensePrice: LicensePriceState;
  licenseStore: LicenseStoreState;
  modals: ModalState;
  navigation: NavigationState;
  openDatx: IOpenDatxFiles;
  openParx: StateOpenParx;
  paramsPage: ParamsPageState;
  persistantState: PeristantStateState;
  projects: ProjectState;
  settings: StateSettings;
  session: SessionState;
  updates: UpdatesState;
}

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk = ThunkAction<void, StoreApi, unknown, AnyAction>;

import React, { useEffect, useState } from "react";
import {
  Layout,
  Form,
  Input,
  Row,
  Col,
  Typography,
  notification,
  Divider
} from "antd";
import { PrimaryButton } from "../components/Common/CommonButtons";
import { NormalCard } from "../components/Common/CommonCards";
import { SmallText } from "../components/Common/CommonFonts";
import {
  LockOutlined,
  QuestionCircleOutlined,
  UserOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginDetails,
  useGetCompanyDetailsQuery,
  useRequestAuthMutation,
  appType
} from "../state/cargologRestApi";
import {
  selectForcedEndSession,
  setForcedEndSession,
  setSessionTokens,
  setValidSetup
} from "../state/persistantStateSlice";
import { setInitalizeSwitcher, setReauthRequired } from "../state/sessionSlice";
import { isUndefined } from "lodash-es";
import { size } from "../helpers/pageHelper";
import wrinkleBackground from "../assets/wrinkle-border.jpg";
import { openChangePasswordModal } from "../state/modalsSlice";
import ChangePasswordModal from "../components/Modals/ChangePasswordModal";
import { useTranslation } from "react-i18next";
import Online from "../components/MicroComponents/Online";
import { SuccessAlert } from "../components/Common/CommonAlerts";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  setCreateUserAccountProgress,
  setCreateUserSuccess
} from "../state/navigationSlice";
import { parseAccessToken } from "../helpers/sessionHelper";
import { MobitronHugeLogo } from "../logos";

const { Link } = Typography;

const iconStyle: React.CSSProperties = { color: "#BFBFBF" };

const LoginPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const forcedEndSession = useSelector(selectForcedEndSession);
  const [requestAuth, authStatus] = useRequestAuthMutation();
  const { isLoading: authIsLoading } = authStatus;

  const [companyId, setCompanyId] = useState<string>();
  const { data: companyData } = useGetCompanyDetailsQuery(
    companyId ? { companyId: companyId } : skipToken
  );

  if (companyData) {
    if (
      companyData.name === "Company Name" ||
      companyData.address === "" ||
      companyData.postalCode === "" ||
      companyData.city === "" ||
      companyData.countryCode === ""
    ) {
      dispatch(setInitalizeSwitcher("registerWizard"));
      dispatch(setCreateUserSuccess(true));
      dispatch(setCreateUserAccountProgress(1));
      dispatch(setValidSetup(false));
    } else {
      dispatch(setInitalizeSwitcher("appLayout"));
      dispatch(setValidSetup(true));
    }
  }

  useEffect(() => {
    if (forcedEndSession === true) {
      dispatch(setForcedEndSession(false));
      notification.warning({ message: t("ImpersonateSessionHasEnded") });
    }
  }, [forcedEndSession]);

  const handleAuthRequest = async (request: LoginDetails) => {
    // Prevent asking for password again directly after login
    dispatch(setReauthRequired(false));
    const result: any = await requestAuth(request);
    if (result.data) {
      dispatch(setSessionTokens(result.data));
      const tokenData = parseAccessToken(result.data.accessToken);
      setCompanyId(tokenData.companyId);
    } else if (result.error) {
      const errorDescription = result.error.data.title;
      notification.error({
        message: t("FailedToSignIn"),
        description: errorDescription
      });
    }
  };

  const [email, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();

  // When user clicks Sign In with valid form
  const onFinish = () => {
    if (!isUndefined(email) && !isUndefined(password)) {
      return handleAuthRequest({ email, password, appType });
    }
  };
  // When user clicks Sign In with invalid form
  const onFinishFailed = () => {};
  return (
    <>
      <Layout style={{ height: "100%" }} className="move-window">
        <Row
          align="middle"
          justify="center"
          style={{ height: "100%", width: "100%" }}
        >
          <NormalCard
            style={{
              width: "450px",
              height: "auto",
              border: 0,
              borderRadius: size.m1,
              overflow: "hidden"
            }}
            className="no-move-window"
          >
            <Row>
              <Col
                xs={8}
                style={{ backgroundImage: `url(${wrinkleBackground})` }}
              ></Col>
              <Col xs={16} style={{ padding: size.l2 }}>
                <div style={{ width: "90%", margin: "10px auto 0 auto" }}>
                  <MobitronHugeLogo />
                </div>
                <Divider />

                <Online>
                  <Form
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      label={t("Email")}
                      name="username"
                      rules={[
                        { required: true, message: t("PleaseEnterYourEmail") }
                      ]}
                      style={{ marginBottom: size.m1 }}
                    >
                      <Input
                        prefix={<UserOutlined style={iconStyle} />}
                        placeholder="user@example.com"
                        value={email}
                        onChange={(e) => setUsername(e.target.value)}
                        autoFocus
                      />
                    </Form.Item>

                    <Form.Item
                      label={t("Password")}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: t("PleaseEnterYourPassword")
                        }
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input.Password
                        prefix={<LockOutlined style={iconStyle} />}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: size.m1 }}>
                      <Row justify="space-between">
                        <Link
                          onClick={() => dispatch(openChangePasswordModal())}
                        >
                          {t("ForgotPassword")}
                        </Link>
                      </Row>
                    </Form.Item>

                    <Form.Item style={{ marginBottom: 0 }}>
                      <PrimaryButton
                        style={{ width: "100%", height: "36px" }}
                        htmlType="submit"
                        loading={authIsLoading}
                      >
                        {t("SignIn")}
                      </PrimaryButton>
                    </Form.Item>
                    <SmallText
                      type="secondary"
                      style={{
                        textAlign: "center",
                        fontSize: 12
                      }}
                    >
                      By signing in, you agree to our{" "}
                      <Link
                        href={"https://mobitron.com/terms-of-service/"}
                        title={"Terms of Service"}
                        target="_blank"
                        style={{
                          fontSize: 12
                        }}
                      >
                        Terms of Service
                      </Link>
                    </SmallText>
                  </Form>
                  <SuccessAlert
                    style={{
                      marginTop: size.xl1
                    }}
                    message={
                      <>
                        <Row justify="center">
                          <SmallText>
                            <QuestionCircleOutlined />{" "}
                            {t("DontHaveALicenseYet")}
                          </SmallText>
                        </Row>
                        <Row justify="center">
                          <SmallText>
                            <Link
                              onClick={() =>
                                dispatch(setInitalizeSwitcher("registerWizard"))
                              }
                            >
                              <SmallText strong>{t("CreateAccount")}</SmallText>
                            </Link>
                            <SmallText> {t("toBuyOne")}.</SmallText>
                          </SmallText>
                        </Row>
                      </>
                    }
                  />
                </Online>
              </Col>
            </Row>
          </NormalCard>
        </Row>
        <ChangePasswordModal />
      </Layout>
    </>
  );
};

export default LoginPage;

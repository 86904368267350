import dayjs from "dayjs";
import AccHistogramGraph from "../../components/GraphPage/AccHistogramGraph";
import AngleTable from "../../components/GraphPage/AngleTable";
import DvaGraph from "../../components/GraphPage/DvaGraph";
import ExternalIOTable from "../../components/GraphPage/ExternalIOTable";
import ExternalTimersTable from "../../components/GraphPage/ExternalTimersTable";
import MinMaxTable from "../../components/GraphPage/MinMaxTable";
import PrimaryGraph from "../../components/GraphPage/PrimaryGraph";
import QuickReport from "../../components/GraphPage/QuickReport";
import TopAccTable from "../../components/GraphPage/TopAccTable";
import GeneralRecordingInformation from "../../components/MicroComponents/GeneralRecordingInformation";
import { ExportableItem } from "../../components/PrintExport/pdfMultiExport";
import { VMDvaData } from "../dataModelHelper";
import { ItemHeaderData } from "./pdfInterfaces";

/**
 * Creates an array of exportable items containing the dva graph from every index in selectedIndexes
 * @param dvaData
 * @param selectedIndexes
 */
export const getExportableDvaGraphsFromSelected = (
  dvaData: VMDvaData[],
  selectedIndexes: number[]
): ExportableItem[] => {
  if (!(selectedIndexes?.length > 0)) {
    return [];
  }

  return selectedIndexes?.map((arrayIndex) => {
    const dvaDataBlock = dvaData?.[arrayIndex];
    const dvaItem: ExportableItem = {
      ComponentBody: DvaGraph,
      componentProps: { dvaDataBlock }
    };
    return dvaItem;
  });
};

export const getExportableDvaHeadersFromSelected = (
  dvaData: VMDvaData[],
  selectedIndexes: number[],
  timeZone: string
): ItemHeaderData[] => {
  if (!(selectedIndexes?.length > 0)) return [];

  return selectedIndexes?.map((arrayIndex) => {
    const dvaDataBlock = dvaData?.[arrayIndex];
    const reportStart = dayjs
      .unix(dvaDataBlock.start)
      .tz(timeZone)
      .format("YYYY-MM-DD, HH:mm:ss");
    const reportEnd = dayjs
      .unix(dvaDataBlock.end)
      .tz(timeZone)
      .format("YYYY-MM-DD, HH:mm:ss");
    return {
      itemType: "DetailedVibrationAnalysis",
      itemTitle: "DVA: " + reportStart + " - " + reportEnd
    };
  });
};

interface GetExportableItem {
  (props: any): {
    ComponentBody: React.FC<any>;
    componentProps: any;
  };
}

/** Creates an exportable item for primary graph that is usefull for multipage reports */
export const getPrimaryGraphExportableItem: GetExportableItem = (
  props: any
) => {
  const ComponentBody = PrimaryGraph;
  return { ComponentBody, componentProps: props };
};

/** Creates an exportable item for min/max that is usefull for multipage reports */
export const getMinMaxTableExportableItem: GetExportableItem = (props: any) => {
  const ComponentBody = MinMaxTable;
  return { ComponentBody, componentProps: props };
};

/** Creates an exportable item for Acceleration histogram graph that is usefull for multipage reports */
export const getAccHistogramGraphExportableItem: GetExportableItem = (
  props: any
) => {
  const ComponentBody = AccHistogramGraph;
  return { ComponentBody, componentProps: props };
};

/** Creates an exportable item for top accelerations table that is usefull for multipage reports */
export const getTopAccTableExportableItem: GetExportableItem = (props: any) => {
  const ComponentBody = TopAccTable;
  return { ComponentBody, componentProps: props };
};

/** Creates an exportable item general recording information that is usefull for multipage reports */
export const getGeneralRecordingInfoExportableItem: GetExportableItem = (
  props: any
) => {
  const ComponentBody = GeneralRecordingInformation;
  return { ComponentBody, componentProps: props };
};

/** Creates an exportable item for Quick Report that is usefull for multipage reports */
export const getQuickReportExportableItem: GetExportableItem = (props: any) => {
  const ComponentBody = QuickReport;
  return { ComponentBody, componentProps: props };
};

/** Creates an exportable item for external IO table */
export const getExtIOTableExportableItem: GetExportableItem = (props: any) => {
  const ComponentBody = ExternalIOTable;
  return { ComponentBody, componentProps: props };
};

/** Creates an exportable item for external timers table */
export const getExtTimersTableExportableItem: GetExportableItem = (
  props: any
) => {
  const ComponentBody = ExternalTimersTable;
  return { ComponentBody, componentProps: props };
};

/** Creates an exportable item for angle table */
export const getAngleTableExportableItem: GetExportableItem = (props: any) => {
  const ComponentBody = AngleTable;
  return { ComponentBody, componentProps: props };
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { VectorData, VectorList, ChannelData } from "./DvaStatusGraph";
import StandardModal from "../Common/StandardModal";
import { size } from "../../helpers/pageHelper";
import { selectVectorData } from "../../state/persistantStateSlice";
import { useSelector } from "react-redux";
import { dvaSampleToMsTickFormaterWithPostfix } from "../../helpers/graphHelper";

const { Text } = Typography;

interface IProps {
  data?: VectorList;
  isModalOpen: boolean;
  closeModal: () => void;
}
const DvaVectorsModal: React.FC<IProps> = (props) => {
  const { data, isModalOpen, closeModal } = props;
  const { t } = useTranslation();

  const { vectorTimespan, vectorSize } = useSelector(selectVectorData);

  const columns: ColumnsType<VectorData> = [
    {
      title: t("xAcc_tooltip"),
      dataIndex: "xData",
      key: "x",
      children: [
        {
          title: t("Timespan"),
          dataIndex: "xData",
          key: "startEnd",
          render: (xData?: ChannelData) => (
            <>
              {xData && (
                <Text>
                  {dvaSampleToMsTickFormaterWithPostfix(xData.startEnd[0]) +
                    " - " +
                    dvaSampleToMsTickFormaterWithPostfix(xData.startEnd[1])}
                </Text>
              )}
            </>
          )
        },
        {
          title: t("headerCurrentValue"),
          dataIndex: "xData",
          key: "xAlarm",
          render: (xData?: ChannelData) => (
            <>
              {xData && (
                <Text>
                  {xData.alarm}
                  {"g"}
                </Text>
              )}
            </>
          )
        },
        {
          title: t("Difference"),
          dataIndex: "xData",
          key: "xDifference",
          render: (xData?: ChannelData) => (
            <>
              {xData && (
                <Text>
                  {xData.alarmDiff}
                  {"g"}
                </Text>
              )}
            </>
          )
        }
      ]
    },
    {
      title: t("yAcc_tooltip"),
      dataIndex: "yData",
      key: "y",
      children: [
        {
          title: t("Timespan"),
          dataIndex: "yData",
          key: "startEnd",
          render: (yData?: ChannelData) => (
            <>
              {yData && (
                <Text>
                  {dvaSampleToMsTickFormaterWithPostfix(yData.startEnd[0]) +
                    " - " +
                    dvaSampleToMsTickFormaterWithPostfix(yData.startEnd[1])}
                </Text>
              )}
            </>
          )
        },
        {
          title: t("headerCurrentValue"),
          dataIndex: "yData",
          key: "yAlarm",
          render: (yData?: ChannelData) => (
            <>
              {yData && (
                <Text>
                  {yData.alarm}
                  {"g"}
                </Text>
              )}
            </>
          )
        },
        {
          title: t("Difference"),
          dataIndex: "yData",
          key: "yDifference",
          render: (yData?: ChannelData) => (
            <>
              {yData && (
                <Text>
                  {yData.alarmDiff}
                  {"g"}
                </Text>
              )}
            </>
          )
        }
      ]
    },
    {
      title: t("zAcc_tooltip"),
      dataIndex: "zData",
      key: "z",
      children: [
        {
          title: t("Timespan"),
          dataIndex: "zData",
          key: "startEnd",
          render: (zData?: ChannelData) => (
            <>
              {zData && (
                <Text>
                  {dvaSampleToMsTickFormaterWithPostfix(zData.startEnd[0]) +
                    " - " +
                    dvaSampleToMsTickFormaterWithPostfix(zData.startEnd[1])}
                </Text>
              )}
            </>
          )
        },
        {
          title: t("headerCurrentValue"),
          dataIndex: "zData",
          key: "zAlarm",
          render: (zData?: ChannelData) => (
            <>
              {zData && (
                <Text>
                  {zData.alarm}
                  {"g"}
                </Text>
              )}
            </>
          )
        },
        {
          title: t("Difference"),
          dataIndex: "zData",
          key: "zDifference",
          render: (zData?: ChannelData) => (
            <>
              {zData && (
                <Text>
                  {zData.alarmDiff}
                  {"g"}
                </Text>
              )}
            </>
          )
        }
      ]
    }
  ];

  return (
    <>
      <StandardModal
        title={t("VectorsList")}
        open={isModalOpen}
        onCancel={() => closeModal()}
        footer={null}
        width={"95%"}
        zIndex={1045}
        styles={{ body: { padding: 0 } }}
      >
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: size.m2, paddingLeft: size.l1 }}
        >
          {`${t("CurrentVectorSettings")}: ${t("Timespan")}: ${vectorTimespan} ms, ${t("VectorSize")}: ${vectorSize}g`}
        </Row>
        <Table
          columns={columns}
          dataSource={data?.data}
          pagination={false}
          scroll={{ y: "calc(100vh - 400px)" }}
        />
      </StandardModal>
    </>
  );
};

export default DvaVectorsModal;

import React, { useState } from "react";
import {
  Card,
  Checkbox,
  Col,
  Dropdown,
  Menu,
  Row,
  Typography,
  Table
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { ColumnType } from "antd/lib/table/interface";
import { useDispatch, useSelector } from "react-redux";
import {
  createDateTupleWithOffset,
  createTzDate
} from "../../helpers/dateHelper";
import { IDtDva } from "../../helpers/parsers/parseDvaHelper";
import {
  removeDvaCursorPosition,
  selectDashboardHeader,
  selectDvaTableInfo,
  selectForDashboardRangePicker,
  selectProjectInfo,
  setActiveDvaBlock,
  setMarkedDvaBlockIndexes
} from "../../state/openDatxSlice";
import { defaultTableColumnPropsNumber } from "../../constants/defaultComponentsProps";
import { size } from "../../helpers/pageHelper";
import {
  DeliveredProcedureOutlined,
  ExportOutlined,
  FilePdfOutlined,
  FundViewOutlined,
  RiseOutlined
} from "@ant-design/icons";
import {
  selectCsvFormat,
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import { SmallText } from "../Common/CommonFonts";
import { useExportPdfHook } from "../PrintExport/pdfExport";
import {
  DocumentProps,
  ItemHeaderData,
  TransportHeaderData
} from "../../helpers/pdf/pdfInterfaces";
import { isEmpty, isNil, range } from "lodash-es";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import { dvaDataToCsv } from "../../helpers/dataExportHelper";
import { ExportMultipleComponent } from "../PrintExport/pdfMultiExport";

import {
  getExportableDvaGraphsFromSelected,
  getExportableDvaHeadersFromSelected
} from "../../helpers/pdf/pdfExportHelper";
import { getUser } from "../../state/sessionSlice";
import { useTranslation } from "react-i18next";
import { MenuButton } from "../Common/CommonButtons";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";
import { VectorList } from "./DvaStatusGraph";
import DvaVectorsModal from "./DvaVectorsModal";
import { appType } from "../../state/cargologRestApi";
import { insertIf } from "../../utils/generalUtils";

const { Link, Text } = Typography;

interface ITableData {
  key: number;
  start: number;
  end: number;
  xAxis: number;
  yAxis: number;
  zAxis: number;
  vectors?: {
    key: number;
    vectorsLength: number;
  };
}

/** Local component: Custom check all button that checks every dva row for every
 *"page" */
const CheckAll = (props: { fileId: string }) => {
  const { markedKeys, dvaData } = useSelector(selectDvaTableInfo(props.fileId));
  const dispatch = useDispatch();

  const allChecked = markedKeys?.length === dvaData.length;
  const indeterminate = markedKeys.length > 0 && !allChecked;

  const allIndexes = range(dvaData.length);
  const fileId = props.fileId;
  const toggleCheckAll = () => {
    if (allChecked) {
      dispatch(setMarkedDvaBlockIndexes({ fileId, selectedIndexes: [] }));
    } else {
      dispatch(
        setMarkedDvaBlockIndexes({ fileId, selectedIndexes: allIndexes })
      );
    }
  };

  return (
    <Checkbox
      indeterminate={indeterminate}
      checked={allChecked}
      onChange={toggleCheckAll}
    />
  );
};

interface IProps {
  fileId: string;
  tableHeight: number;
  style?: React.CSSProperties;
  vectorData?: VectorList[];
}
const DvaTable = (props: IProps) => {
  const { fileId, tableHeight, vectorData } = props;
  const { dvaData, markedKeys, timezone, activeKey } = useSelector(
    selectDvaTableInfo(props.fileId)
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const absMaxForAxis = (d: IDtDva[]) =>
    d.reduce(
      (result, curr) => {
        const { xAlarm, yAlarm, zAlarm } = curr;

        const x = Math.abs(xAlarm) > Math.abs(result.x) ? xAlarm : result.x;
        const y = Math.abs(yAlarm) > Math.abs(result.y) ? yAlarm : result.y;
        const z = Math.abs(zAlarm) > Math.abs(result.z) ? zAlarm : result.z;

        return { x, y, z };
      },
      { x: 0, y: 0, z: 0 }
    );

  const tableData: ITableData[] = dvaData.map((item, index) => {
    const { start, data, end } = item;

    const key = index;
    const { x: xAxis, y: yAxis, z: zAxis } = absMaxForAxis(data);
    const vectorsLength = vectorData?.[index]?.data.length;

    return {
      key,
      start,
      end,
      xAxis,
      yAxis,
      zAxis,
      vectors: {
        key,
        vectorsLength: vectorsLength ? vectorsLength : 0
      }
    };
  });

  const selectedTableData: ITableData[] = tableData.filter((item) =>
    markedKeys.includes(item.key)
  );

  const renderPreviewBtn = (blockKey: number) => {
    const onClick = () => {
      dispatch(removeDvaCursorPosition());
      dispatch(setActiveDvaBlock({ fileId, blockKey }));
    };

    return (
      <Link onClick={onClick}>
        <FundViewOutlined /> {t("View")}
      </Link>
    );
  };

  /** Common props that should be used for column x/y/z-axis */
  const accCommonAxisProps: ColumnType<ITableData> = {
    ...defaultTableColumnPropsNumber,
    align: "right",
    render: (item: number) => `${item}g`
  };

  const csvFormat = useSelector(selectCsvFormat);

  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);
  const curTimezone = timezoneSelector(timezone, timezoneState, timezoneToggle);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVectorsRow, setSelectedVectorsRow] = useState<number | null>(
    null
  );

  const openModal = (vectorsRow: number) => {
    setSelectedVectorsRow(vectorsRow);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns: ColumnsType<ITableData> = [
    {
      title: "",
      dataIndex: "renderPreviewBtn",
      key: "renderPreviewBtn",
      render: (_, row) => renderPreviewBtn(row.key)
    },
    {
      ...defaultTableColumnPropsNumber,
      title: t("StartTime"),
      dataIndex: "start",
      key: "start",
      render: (item) =>
        createTzDate(item, curTimezone).format("YYYY-MM-DD, HH:mm:ss"),
      sorter: (a, b) => a.start - b.start
    },
    {
      ...defaultTableColumnPropsNumber,
      title: t("EndTime"),
      dataIndex: "end",
      key: "end",
      render: (item) =>
        createTzDate(item, curTimezone).format("YYYY-MM-DD, HH:mm:ss"),
      sorter: (a, b) => a.end - b.end
    },
    {
      ...accCommonAxisProps,
      title: "X max",
      dataIndex: "xAxis",
      key: "xAxis",
      sorter: (a, b) => Math.abs(a.xAxis) - Math.abs(b.xAxis)
    },
    {
      ...accCommonAxisProps,
      title: "Y max",
      dataIndex: "yAxis",
      key: "yAxis",
      sorter: (a, b) => Math.abs(a.yAxis) - Math.abs(b.yAxis)
    },
    {
      ...accCommonAxisProps,
      title: "Z max",
      dataIndex: "zAxis",
      key: "zAxis",
      sorter: (a, b) => Math.abs(a.zAxis) - Math.abs(b.zAxis)
    },
    ...insertIf(appType === "win-dev", {
      title: t("VectorLeaps"),
      dataIndex: "vectors",
      key: "vectors",
      // For some reason this doesn't work inside insertIf()
      // align: "right",
      render: (data: { key: number; vectorsLength: number }) => {
        return (
          <>
            {data.vectorsLength > 0 ? (
              <Link onClick={() => openModal(data.key)}>
                <RiseOutlined /> {data.vectorsLength ?? 0}
              </Link>
            ) : (
              <Text style={{ paddingRight: size.s1 }}>
                {data.vectorsLength}
              </Text>
            )}
          </>
        );
      }
    })
  ];

  const selectedColumns: ColumnsType<ITableData> = [
    {
      ...defaultTableColumnPropsNumber,
      title: t("StartTime"),
      dataIndex: "start",
      key: "start",
      render: (item) =>
        createTzDate(item, curTimezone).format("YYYY-MM-DD, HH:mm:ss"),
      sorter: (a, b) => a.start - b.start
    },
    {
      ...defaultTableColumnPropsNumber,
      title: t("EndTime"),
      dataIndex: "end",
      key: "end",
      render: (item) =>
        createTzDate(item, curTimezone).format("YYYY-MM-DD, HH:mm:ss"),
      sorter: (a, b) => a.end - b.end
    },
    {
      ...accCommonAxisProps,
      title: "X max",
      dataIndex: "xAxis",
      key: "xAxis",
      sorter: (a, b) => Math.abs(a.xAxis) - Math.abs(b.xAxis)
    },
    {
      ...accCommonAxisProps,
      title: "Y max",
      dataIndex: "yAxis",
      key: "yAxis",
      sorter: (a, b) => Math.abs(a.yAxis) - Math.abs(b.yAxis)
    },
    {
      ...accCommonAxisProps,
      title: "Z max",
      dataIndex: "zAxis",
      key: "zAxis",
      sorter: (a, b) => Math.abs(a.zAxis) - Math.abs(b.zAxis)
    },
    ...insertIf(appType === "win-dev", {
      title: t("VectorLeaps"),
      dataIndex: "vectors",
      key: "vectors",
      // For some reason this doesn't work inside insertIf()
      // align: "right",
      render: (data: { key: number; vectorsLength: number }) => {
        return (
          <>
            {data.vectorsLength > 0 ? (
              <Link onClick={() => openModal(data.key)}>
                <RiseOutlined /> {data.vectorsLength ?? 0}
              </Link>
            ) : (
              <Text style={{ paddingRight: size.s1 }}>
                {data.vectorsLength}
              </Text>
            )}
          </>
        );
      }
    })
  ];

  /** Hook: Export all the selected dva-blocks in the dva-block-table */
  const multiExport = useExportPdfHook();

  const exportSelectedDvaAsCsv = () => {
    if (isEmpty(markedKeys)) {
      displayErrorMessage(t("NoDvaEventsSelected"));

      return;
    }

    const selectedDva = markedKeys!.map((dvaIndex) => dvaData[dvaIndex]);
    const content = dvaDataToCsv(
      selectedDva,
      csvFormat,
      timezone,
      timezoneState,
      timezoneToggle,
      t
    );

    saveAsCsv(content);
  };

  const dvaGraphsToExport = !isNil(markedKeys)
    ? getExportableDvaGraphsFromSelected(dvaData, markedKeys!)
    : undefined;

  const exportMenu = (
    <>
      <Menu>
        <Menu.Item key="exportPdf" onClick={() => multiExport.startExport()}>
          <FilePdfOutlined /> PDF
        </Menu.Item>
        <Menu.Item
          key="exportCsv"
          onClick={() => {
            exportSelectedDvaAsCsv();
          }}
        >
          <DeliveredProcedureOutlined /> CSV
        </Menu.Item>
      </Menu>
    </>
  );

  const projectInfo = useSelector(selectProjectInfo(fileId));
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(fileId))!;

  const { dataDomain } = useSelector(selectForDashboardRangePicker(fileId));

  const entireDomain = createDateTupleWithOffset(
    dataDomain!,
    timezoneSelector(timezone, timezoneState, timezoneToggle)
  );

  const transportHeader: TransportHeaderData = {
    reportType: "DetailedVibrationAnalysis",
    projectName: projectInfo.projectName ?? "",
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss"),
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss"),
    exportedBy: user,
    deviceId: serialNumber
  };

  const headers: ItemHeaderData[] = !isNil(markedKeys)
    ? getExportableDvaHeadersFromSelected(dvaData, markedKeys!, curTimezone)
    : [];

  const documentProps: DocumentProps = {
    documentTitle: t("DetailedVibrationAnalysis"),
    fileName: "dva_graphs.pdf"
  };

  return (
    <>
      {/* Export many dva graphs */}
      {dvaGraphsToExport && multiExport.isExporting && (
        <ExportMultipleComponent
          //will not be undefined if "markedIndexes" exists
          exportableItems={dvaGraphsToExport}
          itemHeaders={headers}
          transportHeader={transportHeader}
          documentProps={documentProps}
          reportExportDone={multiExport.finishExport}
        />
      )}
      <Card styles={{ body: { padding: "0px 0px 0px 0px" } }}>
        <Row>
          <Col
            span={selectedTableData.length > 0 ? 12 : 24}
            order={1}
            style={{ paddingRight: size.s1 }}
          >
            <Table
              style={{
                width: "100%",
                lineHeight: 24,
                ...props?.style,
                outline: "none"
              }}
              size="small"
              columns={columns}
              dataSource={tableData}
              onRow={(row) => ({
                style: {
                  backgroundColor: "white",
                  filter: row.key === activeKey ? "brightness(90%)" : undefined
                }
              })}
              rowSelection={{
                type: "checkbox",
                columnTitle: <CheckAll fileId={fileId} />,
                selectedRowKeys: markedKeys,
                onChange: (selectedKeys) => {
                  const selectedIndexes = selectedKeys as number[];
                  dispatch(
                    setMarkedDvaBlockIndexes({ fileId, selectedIndexes })
                  );
                }
              }}
              pagination={false}
              scroll={{
                y: tableHeight
              }}
            />
          </Col>
          {selectedTableData.length > 0 && (
            <Col span={12} order={2} style={{ paddingLeft: size.s1 }}>
              <Table
                style={{ width: "100%", lineHeight: 24 }}
                title={() => {
                  const numRows = selectedTableData.length;
                  return (
                    <>
                      <Row
                        justify="space-between"
                        align="middle"
                        style={{ paddingBlock: size.m1, paddingInline: 4 }} // size: special case?
                      >
                        <SmallText strong>
                          {numRows} {numRows > 1 ? "items" : "item"}{" "}
                          {t("selected")}
                        </SmallText>
                        <div>
                          <Dropdown
                            overlay={exportMenu}
                            trigger={["click"]}
                            destroyPopupOnHide
                          >
                            <MenuButton
                              icon={<ExportOutlined />}
                              style={{ marginRight: size.m1 }}
                            >
                              {t("ExportTable")}
                            </MenuButton>
                          </Dropdown>
                        </div>
                      </Row>
                    </>
                  );
                }}
                size="small"
                columns={selectedColumns}
                dataSource={selectedTableData}
                rowSelection={{
                  type: "checkbox",
                  columnTitle: " ",
                  selectedRowKeys: markedKeys,
                  onChange: (selectedKeys) => {
                    const selectedIndexes = selectedKeys as number[];
                    dispatch(
                      setMarkedDvaBlockIndexes({ fileId, selectedIndexes })
                    );
                  }
                }}
                pagination={false}
                scroll={{
                  y: tableHeight - 64
                }}
              />
            </Col>
          )}
        </Row>
      </Card>

      {appType === "win-dev" && !isNil(selectedVectorsRow) && (
        <DvaVectorsModal
          data={vectorData?.[selectedVectorsRow]}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default React.memo(DvaTable);

import {
  FullscreenOutlined,
  RollbackOutlined,
  SlidersOutlined
} from "@ant-design/icons";
import { Row, Col } from "antd";
import Sider from "antd/lib/layout/Sider";
import { every, isEmpty, isNil } from "lodash-es";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { date2NumTuple } from "../../helpers/dataModelHelper";
import {
  createDateTupleWithOffset,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import {
  PrimaryGraphZoomDomain,
  ZoomDimension
} from "../../helpers/graphHelper";
import {
  resetGraphAxisScaleDefault,
  resetGraphAxisTickCountDefault,
  resetZoomDomain,
  selectActiveDatxFile,
  selectDashboardHeader,
  selectDataForExternalIOGraph,
  selectDataForExternalTimersGraph,
  selectDataForGraph,
  selectDataForStatusGraph,
  selectDatxToCsvFunc,
  selectForDashboardRangePicker,
  selectGraphDomains,
  selectProjectInfo,
  selectZoomTools,
  setZoomDomain,
  toggleDatxFilter
} from "../../state/openDatxSlice";
import { getUser, selectCsvFormat } from "../../state/sessionSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import MoveableCard from "../Common/MoveableCard";
import { ExtraButtonItem, emptyFunction } from "../Common/MoveableCardProps";
import DataFilterToggles from "../MicroComponents/DataFilter";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { PdfExportComponent, useExportPdfHook } from "../PrintExport/pdfExport";
import {
  TransportHeaderData,
  DocumentProps,
  ItemHeaderData
} from "../../helpers/pdf/pdfInterfaces";
import ExternalIOGraph from "./ExternalIOGraph";
import ExternalTimersGraph from "./ExternalTimersGraph";
import GraphAxisScaleOptions from "./GraphAxisScaleOptions";
import OverviewGraph from "./OverviewGraph";
import PrimaryGraph from "./PrimaryGraph";
import ScoreValues from "./ScoreValues";
import StatusGraph, { StatusGraphData } from "./StatusGraph";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";
import { size } from "../../helpers/pageHelper";
import StandardModal from "../Common/StandardModal";
import { useResizeDetector } from "react-resize-detector";

interface IProps {
  fileId: string;
  maxPoints?: number;
  width: number;
  height: number;
  dragHandle: string;
  closeCard: ExtraButtonItem;
  jumpToDvaCallback?: () => boolean;
}

interface GraphContentProps {
  contentHeight: number;
  statusGraphData: StatusGraphData;
  externalIOGraphData: any;
  externalTimersData: any;
  primaryGraphData: any;
  domains: any;
  primaryGraphWidth: number;
  zoomDimension?: ZoomDimension | undefined;
  handleZoomDomain: (domain: PrimaryGraphZoomDomain) => void;
  scoreValuesCursorPos: number | undefined;
  fileId: string;
}

interface ScoreValuesSiderProps {
  siderWidth: number;
  scoreValueHeight: number;
  fileId: string;
  jumpToDvaCallback?: () => boolean;
}

const GraphContent = (props: GraphContentProps) => {
  const dispatch = useDispatch();
  const {
    contentHeight,
    statusGraphData,
    externalIOGraphData,
    externalTimersData,
    primaryGraphData,
    domains,
    primaryGraphWidth,
    zoomDimension,
    handleZoomDomain,
    scoreValuesCursorPos,
    fileId
  } = props;

  /** Determine if the the status graph is renderable */
  const shouldRenderStatusGraph: boolean = (() => {
    const {
      angleData,
      startupData,
      runningStatusData,
      deviceHealthData,
      dataFilters
    } = statusGraphData;

    const renderAngleData =
      !isEmpty(angleData) && dataFilters.angle.dataToggle.isActive;

    const renderStartUpData = !isEmpty(startupData);
    const renderBtnPressData = !isEmpty(runningStatusData);
    const renderDeviceHealthData = !isEmpty(deviceHealthData);

    if (
      !(
        renderAngleData ||
        renderStartUpData ||
        renderBtnPressData ||
        renderDeviceHealthData
      )
    ) {
      return false;
    }

    return true;
  })();

  /** Determine if the the status graph is renderable */
  const shouldRenderExternalTimersGraph: boolean = (() => {
    const { dataFilters, externalTimers } = externalTimersData;

    const renderExternalTimersData =
      !isEmpty(externalTimers) && dataFilters.extTimer.dataToggle.isActive;
    return renderExternalTimersData ? true : false;
  })();

  const shouldRenderExternalInput: boolean = (() => {
    const { dataFilters, externalInputs } = externalIOGraphData;
    const renderExternalInputsData =
      !isEmpty(externalInputs) && dataFilters.extInput.dataToggle.isActive;
    return renderExternalInputsData ? true : false;
  })();

  const shouldRenderExternalOutput: boolean = (() => {
    const { dataFilters, externalOutputs } = externalIOGraphData;
    const renderExternalOutputsData =
      !isEmpty(externalOutputs) && dataFilters.extOutput.dataToggle.isActive;
    return renderExternalOutputsData ? true : false;
  })();

  /** Determine if PrimaryGraph and OverviewGraph is renderable */
  const shouldRenderNormalGraphs = every(domains, (d) => !isNil(d));

  // Heights of the sub graphs
  const statusGraphHeight = shouldRenderStatusGraph ? 80 : 0;

  // Height of external IO graph
  const ioLines = (shouldRender: boolean, ioConfigured: number) => {
    if (!shouldRender) return 0;
    if (ioConfigured > 0) return ioConfigured * 16;
    return 8 * 16;
  };

  const inputsConfigured =
    externalIOGraphData.externalInputParams?.filter((input: any) => input.used)
      .length ?? 0;
  const outputsConfigured =
    externalIOGraphData.externalOutputParams?.filter(
      (output: any) => output.used
    ).length ?? 0;

  let externalIOGraphHeight = 0;
  externalIOGraphHeight += ioLines(shouldRenderExternalInput, inputsConfigured);
  externalIOGraphHeight += ioLines(
    shouldRenderExternalOutput,
    outputsConfigured
  );
  externalIOGraphHeight += externalIOGraphHeight > 0 ? 50 : 0; // Icons graph

  const externalTimersGraphHeight = shouldRenderExternalTimersGraph ? 50 : 0;

  const overviewGraphHeight = 115;

  // Calculating height for main graph. Will be below 0 before containerHeight is finish calcuating.
  const primaryGraphHeightCalc =
    contentHeight -
    statusGraphHeight -
    overviewGraphHeight -
    externalIOGraphHeight -
    externalTimersGraphHeight;

  // Height of main graph
  const primaryGraphHeight =
    primaryGraphHeightCalc > 0 ? primaryGraphHeightCalc : 0;

  return (
    <div
      style={{
        height: contentHeight,
        overflow: "hidden"
      }}
    >
      {shouldRenderStatusGraph && (
        <StatusGraph
          data={statusGraphData}
          zoomDomain={domains.zoomDomain}
          width={primaryGraphWidth}
          height={statusGraphHeight}
        />
      )}
      {(shouldRenderExternalInput || shouldRenderExternalOutput) && (
        <ExternalIOGraph
          data={externalIOGraphData}
          zoomDomain={domains.zoomDomain}
          width={primaryGraphWidth - 30}
          height={externalIOGraphHeight}
          scoreValuesCursorPos={scoreValuesCursorPos}
        />
      )}

      {shouldRenderExternalTimersGraph && (
        <ExternalTimersGraph
          data={externalTimersData}
          zoomDomain={domains.zoomDomain}
          width={primaryGraphWidth}
          height={externalTimersGraphHeight}
        />
      )}

      {shouldRenderNormalGraphs && (
        <PrimaryGraph
          data={primaryGraphData}
          width={primaryGraphWidth}
          height={primaryGraphHeight}
          zoomDomain={domains.zoomDomain}
          zoomDimension={zoomDimension}
          handleZoom={handleZoomDomain}
        />
      )}

      {shouldRenderNormalGraphs && (
        <OverviewGraph
          data={primaryGraphData}
          width={primaryGraphWidth}
          height={overviewGraphHeight}
          zoomDomain={domains.zoomDomain}
          zoomDimension={zoomDimension}
          handleZoom={handleZoomDomain}
          resetZoom={() => dispatch(resetZoomDomain({ fileId }))}
          activeDomain={domains.activeDomain}
        />
      )}
    </div>
  );
};

const ScoreValuesSider = (props: ScoreValuesSiderProps) => {
  const { scoreValueHeight, siderWidth, fileId, jumpToDvaCallback } = props;
  return (
    <Sider
      width={siderWidth}
      style={{
        backgroundColor: "#fff",
        height: scoreValueHeight
      }}
    >
      <div
        style={{
          height: scoreValueHeight
        }}
      >
        <ScoreValues
          id={fileId}
          scoreValueHeight={scoreValueHeight}
          jumpToDvaCallback={jumpToDvaCallback}
        />
      </div>
    </Sider>
  );
};

const ClassicGraphDashboardCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeFile = useSelector(selectActiveDatxFile);
  const primaryGraphData = useSelector(selectDataForGraph(props.fileId));
  const statusGraphData = useSelector(selectDataForStatusGraph(props.fileId));
  const externalIOGraphData = useSelector(
    selectDataForExternalIOGraph(props.fileId)
  );
  const externalSensorsData = useSelector(
    selectDataForExternalTimersGraph(props.fileId)
  );
  const domains = useSelector(selectGraphDomains(props.fileId));
  const projectInfo = useSelector(selectProjectInfo(props.fileId));
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;

  const { zoomDimension, hasZoomed } = useSelector(
    selectZoomTools(props.fileId)
  );
  const csvFormat = useSelector(selectCsvFormat);
  const getDatxAsCsv = useSelector(
    selectDatxToCsvFunc(props.fileId, csvFormat)
  );

  const { dataDomain, timezone } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );

  const { width, ref } = useResizeDetector();

  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);
  const utcOffsetStr = createUtcOffsetStr(timezone);

  // Local state for modals
  const [showGraphScaleModal, setShowGraphScaleModal] = useState(false);
  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const windowWidth = window.innerWidth;

  /** If Score Values is visible or hidden */
  const isCursorPosSelected =
    activeFile?.scoreValuesCursorPosPrimaryGraph ?? false;
  const scoreValuesCursorPos = isCursorPosSelected
    ? activeFile?.scoreValuesCursorPos
    : undefined;
  /** If Score Values is on the left or the right side of graph */
  const isScoreValuesOnRightSide = activeFile?.scoreValuesSideToggle ?? true;

  /** Determine if PrimaryGraph and OverviewGraph is renderable */
  const shouldRenderNormalGraphs = every(domains, (d) => !isNil(d));

  const bodyPadding = 24;
  const siderWidth = 270; // Width of the score values sider

  // Dimensions for the graph content
  const contentHeight = props.height - 48;
  const scoreValueHeight = props.height;
  const primaryGraphWidth =
    isCursorPosSelected && width ? width - siderWidth : width!;

  // Dimensions for the full screen modal
  const modalContentHeight = window.innerHeight - 64 - 152;
  const modalScoreValueHeight = window.innerHeight - bodyPadding - 152;
  const modalPrimaryGraphWidth = isCursorPosSelected
    ? windowWidth - siderWidth - bodyPadding - 48 - 1
    : windowWidth - 48;

  const handleZoomDomain = useCallback(
    (domain: PrimaryGraphZoomDomain) => {
      const xNumTuple = date2NumTuple(domain.x);

      const fileId = props.fileId;
      const newDomain = { x: xNumTuple, y: domain.y };

      dispatch(setZoomDomain({ fileId, newDomain }));
    },
    [dispatch, props.fileId]
  );

  const exportDatxAsCsv = () => {
    const datxAsCsv = getDatxAsCsv();

    if (isNil(datxAsCsv)) {
      displayErrorMessage(t("FailedToExportCsvDataNoDataAvailable"));
      return;
    }

    saveAsCsv(datxAsCsv);
  };

  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const extraButtons: ExtraButtonItem[] = [
    {
      title: t("SetScale"),
      icon: <SlidersOutlined />,
      button: "custom",
      action: () => setShowGraphScaleModal(true)
    },
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      pdfAction: startExport,
      csvAction: exportDatxAsCsv
    },
    {
      title: t("Fullscreen"),
      icon: <FullscreenOutlined />,
      button: "custom",
      action: () => setShowFullScreenModal(true)
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  const documentProps: DocumentProps = {
    documentTitle: t("PrimaryGraph"),
    fileName: "primary_graph.pdf"
  };

  const itemHeader: ItemHeaderData = {
    itemType: "PrimaryGraph",
    itemTitle: t("PrimaryGraph")
  };

  const transportHeader: TransportHeaderData = {
    hasFilters: projectInfo.isFiltered,
    reportType: "PrimaryGraph",
    projectName: projectInfo.projectName ?? "",
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart:
      dayjs(domains.zoomDomain.x[0])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportEnd:
      dayjs(domains.zoomDomain.x[1])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <>
      <MoveableCard
        title={t("PrimaryGraph")}
        dragHandle={props.dragHandle}
        closeMe={props.closeCard}
        extraButtons={extraButtons}
        fileId={props.fileId}
      >
        {isExporting && (
          <PdfExportComponent
            ComponentBody={PrimaryGraph}
            componentProps={{
              data: primaryGraphData,
              zoomDomain: domains.zoomDomain
            }}
            documentProps={documentProps}
            itemHeader={itemHeader}
            transportHeader={transportHeader}
            reportExportDone={finishExport}
          />
        )}
        <Row wrap={false} ref={ref}>
          {isCursorPosSelected && (
            <Col
              flex={siderWidth + "px"}
              order={isScoreValuesOnRightSide ? 2 : 1}
            >
              <ScoreValuesSider
                siderWidth={siderWidth}
                scoreValueHeight={scoreValueHeight}
                fileId={props.fileId}
              />
            </Col>
          )}
          <Col
            flex={primaryGraphWidth + "px"}
            order={isScoreValuesOnRightSide ? 1 : 2}
          >
            <DataFilterToggles
              fileId={props.fileId}
              zoomDimension={zoomDimension}
              hasZoomed={hasZoomed}
              shouldRenderNormalGraphs={shouldRenderNormalGraphs}
              activeFilters={primaryGraphData.dataFilters}
              toggleFilter={(target) => {
                const id = props.fileId;
                dispatch(toggleDatxFilter({ id, target }));
              }}
            />
            <GraphContent
              contentHeight={contentHeight}
              statusGraphData={statusGraphData}
              externalIOGraphData={externalIOGraphData}
              externalTimersData={externalSensorsData}
              primaryGraphData={primaryGraphData}
              domains={domains}
              primaryGraphWidth={primaryGraphWidth}
              zoomDimension={zoomDimension}
              handleZoomDomain={handleZoomDomain}
              scoreValuesCursorPos={scoreValuesCursorPos}
              fileId={props.fileId}
            />
          </Col>
        </Row>
      </MoveableCard>
      {/* Modal containing settings for graph y-axis scale */}
      <StandardModal
        title={t("SetScale")}
        open={showGraphScaleModal}
        width={400}
        zIndex={1045}
        onCancel={() => setShowGraphScaleModal(false)}
        destroyOnClose={true}
        footer={[
          <NormalButton
            icon={<RollbackOutlined />}
            key="reset"
            title={t("ResetToDefault")}
            onClick={() => {
              dispatch(resetGraphAxisTickCountDefault());
              dispatch(resetGraphAxisScaleDefault());
            }}
          >
            {t("Reset")}
          </NormalButton>,
          <PrimaryButton key="ok" onClick={() => setShowGraphScaleModal(false)}>
            {t("Close")}
          </PrimaryButton>
        ]}
      >
        <GraphAxisScaleOptions fileId={props.fileId} />
      </StandardModal>
      <StandardModal
        title={t("PrimaryGraph")}
        open={showFullScreenModal}
        onCancel={() => setShowFullScreenModal(false)}
        width={windowWidth - 48}
        zIndex={1045}
        closable={true}
        footer={null}
        style={{
          top: "72px",
          paddingBottom: 0
        }}
        styles={{
          content: { padding: 0 },
          body: { padding: 0 }
        }}
      >
        <div
          style={{
            textAlign: "left",
            paddingInline: size.m1,
            margin: 0,
            border: 0,
            height: "calc(100vh - 156px)",
            overflow: "auto",
            outline: "none"
          }}
        >
          <Row wrap={false}>
            {isCursorPosSelected && (
              <Col
                flex={siderWidth + "px"}
                order={isScoreValuesOnRightSide ? 2 : 1}
              >
                <ScoreValuesSider
                  siderWidth={siderWidth}
                  scoreValueHeight={modalScoreValueHeight}
                  fileId={props.fileId}
                />
              </Col>
            )}
            <Col
              flex={modalPrimaryGraphWidth + "px"}
              order={isScoreValuesOnRightSide ? 1 : 2}
            >
              <DataFilterToggles
                fileId={props.fileId}
                zoomDimension={zoomDimension}
                hasZoomed={hasZoomed}
                shouldRenderNormalGraphs={shouldRenderNormalGraphs}
                activeFilters={primaryGraphData.dataFilters}
                toggleFilter={(target) => {
                  const id = props.fileId;
                  dispatch(toggleDatxFilter({ id, target }));
                }}
              />
              <GraphContent
                contentHeight={modalContentHeight}
                statusGraphData={statusGraphData}
                externalIOGraphData={externalIOGraphData}
                externalTimersData={externalSensorsData}
                primaryGraphData={primaryGraphData}
                domains={domains}
                primaryGraphWidth={modalPrimaryGraphWidth}
                zoomDimension={zoomDimension}
                handleZoomDomain={handleZoomDomain}
                scoreValuesCursorPos={scoreValuesCursorPos}
                fileId={props.fileId}
              />
            </Col>
          </Row>
        </div>
      </StandardModal>
    </>
  );
};

export default ClassicGraphDashboardCard;
